<template>
  <div class="agree-wrapper">
    <div class="checkbox-wrapper">
      <input v-model="checked" type="checkbox" :disabled="disabled" id="agree" />
      <label for="agree"></label>
    </div>
    <div class="tooltip-container">
    </div>
    <p class="agree-text p-standard-paragraph">
      <transition name="fade">
        <floating-tooltip @clicked="$emit('clear')" v-if="shouldShowTooltip(error)" :errors="agreeErrors" />
      </transition>
      <span @click="checked = !checked">
        <slot>
          個人情報保護方針と会員規約に同意のうえ送信してください。
        </slot>
      </span>
    </p>
  </div>
</template>

<script>
import FloatingTooltip from "@/components/common/FloatingTooltip";

export default {
  name: 'CommonAgreeCheck',
  components: {
    FloatingTooltip,
	},
	props: ["value", "error", "disabled"],
	computed: {
		checked: {
			get() {
				return this.value;
			},
			set(newValue) {
        if (newValue) {
          this.clearErrors();
        }
				this.$emit('input', newValue)
			}
		}
  },
  methods: {
    clearErrors() {
      this.$emit("clear");
    },
    shouldShowTooltip(error) {
      const shouldShow = error;
      if (shouldShow && !this.isWaiting) {
        window.setTimeout(() => {
          this.clearErrors();
          this.isWaiting = false;
        }, 3000)
        this.isWaiting = true;
      }
      return shouldShow;
    }
  },
  data() {
    return {
      agreeErrors: [
        "確認のうえ、チェックを付けてください。"
      ],
      isWaiting: false
    };
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@charset "UTF-8";
@import "../../assets/style/parts_responsive.scss";

.agree-wrapper {
  display: flex;
  align-items: center;
  margin-top: 42px;
  justify-content: center;
  margin: auto;
  font-size: 12px;
  cursor: pointer;

  ::v-deep .validation-tooltip {
    padding: 6px 16px !important;
  }
}

.fade-enter-active, .fade-leave-active {
  z-index: 10;
  transition: opacity .5s;
  pointer-events: none;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  pointer-events: none;
  z-index: 10;
  opacity: 0;
}
</style>
