<template>
<div class="login-container">
  <div v-if="mode === 'login'" id="login-page" class="content-box">
    <div class="login-header">
      <img src="https://realestate.ki-group.jp/auth0-assets/logo-keiai.png"/>
      <h3>ログイン</h3>
    </div>
    <div id="error-message" class="alert alert-danger"></div>
    <form @submit.prevent="submitLogin">
      <div class="form-group">
        <label for="name">KEIAI ID</label>
        <input
          v-model="form.email"
          type="email"
          class="form-control"
          id="email"
          placeholder="ご登録メールアドレス">
        <span v-if="err.emailErrorMessage" id="email-error" class="error-message">{{err.emailErrorMessage}}</span>
      </div>
      <div class="form-group">
        <label for="name">パスワード</label>
        <input
          v-model="form.password"
          type="password"
          class="form-control"
          id="password"
          placeholder="半角英数字8文字以上(大文字・記号含む)">
        <span v-if="err.passwordErrorMessage" id="password-error" class="error-message">{{err.passwordErrorMessage}}</span>
      </div>
      <div class="captcha-container form-group"></div>
      <div class="forgot-anchor-wrapper">
        <a @click="mode = 'forgot'" id="btn-forgot">パスワードを忘れた方はこちら</a>
      </div>
      <div class="check-wrapper">
        <label for="auth">
          <input type="checkbox" name="auto" id="auth" checked />
          <div class="check-icon" ></div>
          <span class="check-label">次回から自動でログインする</span>
        </label>
      </div>
      <button
        type="submit"
        id="btn-login"
        class="btn btn-primary btn-block">
          ログイン
      </button>
    </form>
  </div>


  <div v-if="mode === 'forgot'" id="forgot-page" class="content-box forgot-content-box">
    <div class="login-header">
      <img src="https://realestate.ki-group.jp/auth0-assets/logo-keiai.png"/>
      <h3>パスワード再発行</h3>
    </div>
    <div class="text-wrapper">
      <p>ご登録時に設定したKEIAI ID(メールアドレス)を入力してください。</p>
      <p>パスワード再設定用のメールをお送りいたします。</p>
    </div>
    <form @submit.prevent="submitForgotPassword">
      <div class="form-group">
      <label for="name">KEIAI ID</label>
        <input
          type="email"
          v-model="form.forgotEmail"
          class="form-control"
          id="forgot-field"
          placeholder="ご登録メールアドレス">
          <span v-if="err.forgotErrorMessage" id="password-error" class="error-message">{{err.forgotErrorMessage}}</span>
      </div>
      <button
        type="submit"
        :disabled="forgotLoading"
        id="btn-forgot-submit"
        :class="`btn btn-primary btn-block ${forgotLoading ? 'loading-button' : ''}`">
          パスワード再発行
      </button>
    </form>
  </div>

  <div v-if="mode === 'forgot-complete'" id="forgot-complete-page" class="content-box">
    <div class="login-header">
      <img src="https://realestate.ki-group.jp/auth0-assets/logo-keiai.png"/>
      <h3>パスワード再発行</h3>
    </div>
    <div class="text-wrapper forgot-email">
      <p><span id="submitted-email">{{ form.forgotEmail }}</span>宛てに、パスワード再設定用のURLをお送りいたしました。</p>
      <p>メールの受信をご確認いただき、記載されたURLのページよりパスワードを再設定してください。</p>
    </div>
    <button
      type="submit"
      id="return-to-login"
      @click="mode = 'login'"
      class="btn btn-primary btn-block">
        ログイン画面に戻る
    </button>
  </div>
</div>
</template>

<script>

import { forgotPassword } from '@/utils/auth';
import { Auth } from 'aws-amplify';
export default {
  name: 'Login',
  mixins: [],
  components: {},
  data() {
    return {
      mode: "login",
      forgotLoading: false,
      form: {
        email: "",
        forgotEmail: "",
        password: "",
      },
      err: {
        isErr: false,
        emailErrorMessage: "",
        passwordErrorMessage: "",
        msg: '',
      },
    };
  },
  methods: {
    async submitLogin() {
      let valid = true;
      if (this.form.email.length < 1) {
        this.err.emailErrorMessage = "メールアドレスを入力してください。";
        valid = false;
      }
      if (this.form.password.length < 1) {
        this.err.passwordErrorMessage = "パスワードを入力してください。";
        valid = false;
      }
      if (!valid) {
        return;
      }
      try {
        this.err.isErr = false;
        this.err.passwordErrorMessage = '';
        this.err.emailErrorMessage = '';
        await Auth.signIn(this.form.email, this.form.password);
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          this.$router.push("/");
        }
      } catch (e) {
        this.err.isErr = true;
        if (e.code === "UserNotFoundException" | e.code === "NotAuthorizedException") {
          this.err.passwordErrorMessage = "正しいパスワードを入力してください。";
        } else {
          this.err.passwordErrorMessage = "サーバーエラーが発生しました。しばらく待ってからやり直して下さい。";
        }
        console.log(e)
      }
    },
    async submitForgotPassword() {
      if (this.forgotLoading) {
        return;
      }
      let valid = true;
      if (this.form.forgotEmail.length < 1) {
        this.err.emailErrorMessage = "メールアドレスを入力してください。";
        valid = false;
      }
      if (!valid) {
        return;
      }
      try {
        this.forgotLoading = true;
        this.err.isErr = false;
        this.err.forgotErrorMessage = '';
        await forgotPassword(this.form.forgotEmail, window.location.origin);
        this.mode = 'forgot-complete';
      } catch (e) {
        this.err.isErr = true;
        if (e.code === "not_found") {
          this.err.forgotErrorMessage = "登録されているメールアドレスを入力して下さい。";
        } else {
          this.err.forgotErrorMessage = "サーバーエラーが発生しました。しばらく待ってからやり直して下さい。";
        }
      } finally {
        this.forgotLoading = false;
      }

    }
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
body, html {
  height: 100%;
  background-color: white;
}

h3 {
  font-size: 22px;
  margin-top: 36px;
}

.table {
  display:table;
  position:absolute;
  height:100%;
  width:100%;
  background-color: white;
}

.cell {
  display:table-cell;
  vertical-align:middle;
}

.login-container {
  position: relative;
  padding: 25px 0;
  margin-left: auto;
  margin-right: auto;
}

.content-box {
  padding: 15px;
}

.form-group {
    margin-bottom: 15px;
}
.form-group > * {
    margin: 8px 0;
}

.hide {
  display: none;
}

.login-header {
  text-align: center;
}

.login-header img {
  width: 100px;
  height: 40px;
}

input {
  border: 1px solid #CCCCCC !important;
  height: 54px !important;
  border-radius: 30px !important;
}

form {
  margin-top: 30px;
}

.loading-button {
  opacity: .4;
}

.form-control {
  width: 100%;
  padding: 6px 18px;

}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #666666;
  opacity: .5; /* Firefox */
}

.forgot-email {
  margin-top :40px;
}

a {
  text-align: center;
  color: #666666;
  display: block;
  margin-top: 40px;
  font-weight: 400;
}

label {
  font-weight: 400;
}


 p {
  text-align: center;
  font-weight: 400;
  margin: 0;
}

#forgot-page button {
  margin-top: 52px !important;
}

#forgot-page .text-wrapper {
  margin-top: 20px;
  line-height: 1.5;
}

.check-wrapper {
  display: flex;
  margin: auto;
  width: 220px;
  margin-top: 26px;
  align-items: center;
  position: relative;
}
.check-wrapper input {
  margin: 0;
}
.check-wrapper label {
  margin: 0 0 3px 34px;
  color: #666666;
  font-weight: 400;
}
.check-wrapper span {
  cursor: pointer;
}

.btn-primary:focus {
  outline: 0;
}

.btn-primary {
  outline: none !important;
  background-color: red !important;
   color: white;
  font-size: 16px;
  margin-top: 30px !important;
  width: 100% !important;
  border-radius: 26px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  font-size: 18px !important;
  height: 55px !important;
  border-color: #e83532 !important;
}
.btn-primary:hover {
  background-color: red;
  border-color: #e83532 !important;
}

.forgot-anchor-wrapper {
  display: flex;
  justify-content: center;
}

#btn-forgot {
  cursor: pointer;
}

.error-message {
  color: #E83532;
}


input[type=checkbox] {
  display: none;
}

input[type=checkbox]:checked + .check-icon {
  background-image: url(https://realestate.ki-group.jp/auth0-assets/icon_check_full_active@2x.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 2px;
  left: 0;
  cursor: pointer;
}

input[type=checkbox] + .check-icon {
  background-image: url(https://realestate.ki-group.jp/auth0-assets/icon_check_full_inactive@2x.png);
  background-size: 86%;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 0px;
  bottom: 1px;
  left: 0;
  cursor: pointer;
}

@media(min-width: 480px) {
  .login-container {
    width: 486px;
  }

  .content-box {
    border-radius: 30px;
    border: 1px solid #EFEFEF;
    padding: 48px;
  }

  .forgot-content-box {
    padding: 48px 28px;
  }
  .forgot-content-box .form-group {
    width: 360px;
    margin: 30px auto;
  }
  #btn-forgot-submit {
    width: 320px !important;
    margin: 0 auto;
  }

}
</style>
