import Vue from 'vue';

import Router from 'vue-router';

import store from '@/store';
import Mypage from '@/components/mypage/MyPage';
import MemberRegist from '@/components/member/Regist';
import MemberSignup from '@/components/member/Signup';
import MemberForgotPassword from "@/components/member/MemberForgotPassword";
import ReceiveLogin from "@/components/member/ReceiveLogin";
import Login from "@/components/auth/Login";
import Signout from "@/components/common/Signout";
// import { checkSession } from '../utils/auth';
import { Auth } from "aws-amplify";

const router = new Router({
  mode: "history",
  routes: [
    // mypage
    {
      path: "/",
      name: "Mypage",
      component: Mypage
    },
    {
      path: "/signout",
      name: "Signout",
      component: Signout
    },
    {
      path: "/member/regist",
      name: "MemberRegist",
      component: MemberRegist,
      meta: {
        notSignIn: true,
        redirectIfAuthenticated: true
      }
    },
    {
      path: "/member/signup",
      name: "MemberSignup",
      component: MemberSignup,
      meta: {
        notSignIn: true
      }
    },
    {
      path: "/member/login",
      name: "ReceiveLogin",
      component: ReceiveLogin,
      meta: {
        notSignIn: true
      }
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        hideHeader: true,
        hideFooter: true,
        notSignIn: true,
        redirectIfAuthenticated: true
      }
    },
    {
      path: "/complete-password-reset",
      name: "MemberForgotPassword",
      component: MemberForgotPassword,
      meta: {
        hideHeader: true,
        hideFooter: true,
        notSignIn: true,
        redirectIfAuthenticated: true
      }
    }
  ]
});

Vue.use(Router);

// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  let user;
  try {
    // user = await Auth.currentAuthenticatedUser();
    if (store.getters.user && user) {
      user = store.getters.user;
      return;
    } else {
      user = await Auth.currentUserInfo();
      store.commit("setUser", user);
    }
  } catch (e) {
    console.log(e);
    user = null;
  }

  if (user && to.matched.some(record => record.meta.redirectIfAuthenticated)) {
    return next({
      path: "/"
    });
  }

  if (!user && !to.matched.some(record => record.meta.notSignIn)) {
    return next({
      path: "/member/regist"
    });
  }

  return next();
});

export default router;
