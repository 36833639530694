<template>
  <div class="address-select-wrapper">
    <div class="select-wrapper" >
      <ValidationWrapper rules="required" expanded-tooltip>
        <template v-slot:default="slotProps">
          <div class="u-select-wrapper double-sort-arrow">
            <select
              v-model="innerPrefecture"
              @change="getCities($event.target.value)"
              @focus="slotProps.clear"
              name="都道府県"
              :disabled="!prefectureList"
              id="prefecture"
            >
              <option value="" disabled selected>都道府県</option>
              <option v-for="(pref, index) in prefectureList" :key="index" :value="pref.pref_name">{{pref.pref_name}}</option>
            </select>
          </div>
        </template>
      </ValidationWrapper>
    </div>
    <div class="select-wrapper" >
      <ValidationWrapper rules="required" expanded-tooltip>
        <template v-slot:default="slotProps">
          <div class="u-select-wrapper double-sort-arrow">
            <select
              v-model="innerCity"
              @focus="slotProps.clear"
              name="市区町村"
              :disabled="citiesLoading || !cityList || !cityList[0]"
              id="address"
            >
              <option v-if="citiesLoading" value="" disabled selected>Loading...</option>
              <option v-else value="" disabled selected>市区町村</option>
              <option v-for="(city, index) in cityList" :key="index" :value="city.city_name">{{city.city_name}}</option>
            </select>
          </div>
        </template>
      </ValidationWrapper>
    </div>
  </div>
</template>

<script>
import ValidationWrapper from '@/components/common/validation/ValidationWrapper';
import MixinAPI from '@/plugins/mixin-api';

export default {
  name: 'PrefCitySelect',
  props: ['prefectureValue', 'cityValue', 'prefectureList'],
  mixins: [MixinAPI],
  components: { ValidationWrapper },
  data() {
    return {
      citiesLoading: false,
      cityList: null,
    };
  },
  async mounted() {
    if (this.prefectureValue) {
      await this.getCities(this.prefectureValue);
      this.$emit('initialized');
    }
  },
  computed: {
    innerPrefecture: {
      get() {
        return this.prefectureValue;
      },
      set(value) {
        this.$emit('input-prefecture', value);
      },
    },
    innerCity: {
      get() {
        return this.verifiedCity;
      },
      set(value) {
        this.$emit('input-city', value);
      },
    },
    verifiedCity() {
      if (!this.cityList) {
        return '';
      }
      const possibleValues = this.cityList.map(c => c.city_name);
      if (!possibleValues.includes(this.cityValue)) {
        return '';
      }
      return this.cityValue;
    },
  },
  methods: {
    async getCities(prefName) {
      try {
        this.innerCity = '';
        this.citiesLoading = true;
        this.cityList = null;
        const prefecture = this.prefectureList.find(item => item.pref_name === prefName);
        this.cityList = await this.getCityList(0, prefecture.pref_code, 0);
      } catch (e) {
        // console.log(e);
      } finally {
        this.citiesLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
