<template>
  <div />
</template>

<script>
import { signOut } from '@/utils/auth';

export default {
  name: 'Signout',
  async mounted() {
    await signOut();
  },
};
</script>

<style scoped lang="scss"></style>
