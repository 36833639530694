export default class ApiUrls {
  static getPrefectureListUrl(n, kindLaw) {
    let q = '';
    if (n === 1) {
      let kind = '';
      if (kindLaw === 'newly-house' || kindLaw === 1) {
        kind = 1;
      } else if (kindLaw === 'second-hand-house' || kindLaw === 2) {
        kind = 2;
      } else {
        kind = 0;
      }
      if (process.env.VUE_APP_ENV === 'prod') {
        q = `https://gjdxv7pkq4.execute-api.ap-northeast-1.amazonaws.com/default/getPrefectureListDev?kind=${kind}`;
      } else {
        q = `https://jaur6xhxi4.execute-api.ap-northeast-1.amazonaws.com/default/getPrefectureListDev?kind=${kind}`;
      }
    } else {
      q = 'https://open-data.iemarco.jp/api/v1/prefectures';
    }
    return q;
  }

  static getCityListUrl(n, cd, kindLaw) {
    let q = '';
    if (n === 1) {
      let kind = '';
      if (kindLaw === 'newly-house' || kindLaw === 1) {
        kind = 1;
      } else if (kindLaw === 'second-hand-house' || kindLaw === 2) {
        kind = 2;
      } else {
        kind = 0;
      }
      if (process.env.VUE_APP_ENV === 'prod') {
        q = `https://5a6soo2ozi.execute-api.ap-northeast-1.amazonaws.com/default/getCityListDev?pref_code=${cd}&kind=${kind}`;
      } else {
        q = `https://mujl8gxzjl.execute-api.ap-northeast-1.amazonaws.com/default/getCityListDev?pref_code=${cd}&kind=${kind}`;
      }
    } else {
      q = `https://open-data.iemarco.jp/api/v1/cities?pref_code=${cd}`;
    }
    return q;
  }


  static getMypageInfoListUrl(limit) {
    let Uri = 'https://ki-group.microcms.io/api/v1/mypageinfo?filters=isDisplay[equals]true';
    if (limit > 0) Uri += `&limit=${limit}`;
    return Uri;
  }
}
