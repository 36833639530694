<template>
<div class="password-container">
  <div class="content-box">
    <div class="header">
      <img src="https://realestate.ki-group.jp/auth0-assets/logo-keiai.png"/>
      <h3>パスワード変更</h3>
      <h4>新しいパスワードを入力してください。</h4>
    </div>
    
    <div id="error-message" class="alert alert-danger"></div>
    <form @submit.prevent="submitPassword">
      <div class="form-group">
        <label for="name">新しいパスワード</label>
        <input
          v-model="form.password"
          type="password"
          class="form-control"
          id="email"
          placeholder="">
        <span v-if="err.passwordErrorMessage" id="email-error" class="error-message">{{err.emailErrorMessage}}</span>
      </div>
      <div class="form-group">
        <label for="name">新しいパスワード確認(確認)</label>
        <input
          v-model="form.passwordConfirm"
          type="password"
          class="form-control"
          id="password"
          placeholder="">
        <span v-if="err.passwordConfirmErrorMessage" id="password-error" class="error-message">{{err.passwordErrorMessage}}</span>
      </div>
      <div class="captcha-container form-group"></div>
      <button
        type="submit"
        id="btn-login"
        :class="`btn btn-primary btn-block ${loading ? 'loading-button' : ''}`">
          パスワードを変更する
      </button>
    </form>
  </div>
</div>
</template>

<script>
import { completeForgotPassword } from '@/utils/auth';
export default {
  name: 'Login',
  mixins: [],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        password: "",
        passwordConfirm: "",
      },
      err: {
        isErr: false,
        passwordErrorMessage: "",
        passwordConfirmErrorMessage: "",
      },
    };
  },
  mounted() {
    if (!this.$route.query.resetCode) {
      this.$router.push('/');
    }
  },
  methods: {
    async submitPassword() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let valid = true;
      if (this.form.password.length < 1) {
        this.err.passwordErrorMessage = "パスワードを入力してください。";
        valid = false;
      }
      if (this.form.passwordConfirm.length < 1) {
        this.err.passwordConfirmErrorMessage = "パスワードを入力してください。";
        valid = false;
      }
      if (this.form.passwordConfirm !== this.form.password) {
        this.err.passwordConfirmErrorMessage = "パスワードが一等しません";
        valid = false;
      }
      if (!valid) {
        return;
      }
      try {
        this.err.isErr = false;
        this.err.passwordErrorMessage = '';
        this.err.passwordConfirmErrorMessage = '';
        await completeForgotPassword(this.$route.query.un, this.form.password, this.$route.query.resetCode);
        // do something here
        this.$router.push("/login");
      } catch (e) {
        this.err.isErr = true;
        if (e.code === "invalid_code") {
          this.err.passwordConfirmErrorMessage = "有効期限が切れました。また最初からやり直して下さい。"
        } else {
          this.err.passwordConfirmErrorMessage = "エラーが発生しました。"
        }
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
body, html {
  height: 100%;
  background-color: white;
}

h3 {
  font-size: 22px;
  margin-top: 36px;
}

.table {
  display:table;
  position:absolute;
  height:100%;
  width:100%;
  background-color: white;
}

.cell {
  display:table-cell;
  vertical-align:middle;
}

.password-container {
  position: relative;
  padding: 25px 0;
  margin-left: auto;
  margin-right: auto;
}

.content-box {
  padding: 15px;
}

.form-group {
    margin-bottom: 15px;
}
.form-group > * {
    margin: 8px 0;
}

.hide {
  display: none;
}

.header {
  text-align: center;
}

.login-header img {
  width: 100px;
  height: 40px;
}

input {
  border: 1px solid #CCCCCC !important;
  height: 54px !important;
  border-radius: 30px !important;
}

form {
  margin-top: 30px;
}

.loading-button {
  opacity: .4;
}

.form-control {
  width: 100%;
  padding: 6px 18px;

}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #666666;
  opacity: .5; /* Firefox */
}

.forgot-email {
  margin-top :40px;
}

a {
  text-align: center;
  color: #666666;
  display: block;
  margin-top: 40px;
  font-weight: 400;
}

label {
  font-weight: 400;
}


 p {
  text-align: center;
  font-weight: 400;
  margin: 0;
}

#forgot-page button {
  margin-top: 52px !important;
}

#forgot-page .text-wrapper {
  margin-top: 20px;
  line-height: 1.5;
}

.check-wrapper {
  display: flex;
  margin: auto;
  width: 220px;
  margin-top: 26px;
  align-items: center;
  position: relative;
}
.check-wrapper input {
  margin: 0;
}
.check-wrapper label {
  margin: 0 0 3px 34px;
  color: #666666;
  font-weight: 400;
}
.check-wrapper span {
  cursor: pointer;
}

.btn-primary:focus {
  outline: 0;
}

.btn-primary {
  outline: none !important;
  background-color: red !important;
   color: white;
  font-size: 16px;
  margin-top: 30px !important;
  width: 100% !important;
  border-radius: 26px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  font-size: 18px !important;
  height: 55px !important;
  border-color: #e83532 !important;
}
.btn-primary:hover {
  background-color: red;
  border-color: #e83532 !important;
}

.forgot-anchor-wrapper {
  display: flex;
  justify-content: center;
}

#btn-forgot {
  cursor: pointer;
}

.error-message {
  color: #E83532;
}


input[type=checkbox] {
  display: none;
}

input[type=checkbox]:checked + .check-icon {
  background-image: url(https://realestate.ki-group.jp/auth0-assets/icon_check_full_active@2x.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 2px;
  left: 0;
  cursor: pointer;
}

input[type=checkbox] + .check-icon {
  background-image: url(https://realestate.ki-group.jp/auth0-assets/icon_check_full_inactive@2x.png);
  background-size: 86%;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: 0px;
  bottom: 1px;
  left: 0;
  cursor: pointer;
}

@media(min-width: 480px) {
  .login-container {
    width: 486px;
  }

  .content-box {
    border-radius: 30px;
    border: 1px solid #EFEFEF;
    padding: 48px;
  }

  .forgot-content-box {
    padding: 48px 28px;
  }
  .forgot-content-box .form-group {
    width: 360px;
    margin: 30px auto;
  }
  #btn-forgot-submit {
    width: 320px !important;
    margin: 0 auto;
  }

}
</style>
