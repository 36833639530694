<template>
<div id="app">
  <!-- <amplify&#45;sign&#45;out></amplify&#45;sign&#45;out> -->
  <common-header v-if="showHeader" id="header"/>
  <router-view id="content"/>
  <common-footer v-if="showFooter" id="footer"/>
</div>
</template>

<script>
import MixinMeta from '@/plugins/mixin-meta';
import CommonHeader from '@/components/parts/CommonHeader';
import CommonFooter from '@/components/parts/CommonFooter';
// import { Auth } from 'aws-amplify';


export default {
  name: 'App',
  components: {
    CommonHeader,
    CommonFooter
  },
  mixins: [MixinMeta],
  async mounted() {
    this.metaSetForDefault();
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user;
    },
    showHeader() {
      return !this.$route.meta.hideHeader;
    },
    showFooter() {
      return !this.$route.meta.hideFooter;
    },
  }
};
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    font-size: 14px;
  }

  html, body, #app {
    height: 100%;
  }

  #app {
    display: flex;
    flex-direction: column;
  }

  #content {
    flex: 1 0 auto;
  }

  #footer {
    flex-shrink: 0;
  }
  
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:after,
  blockquote:before,
  q:after,
  q:before {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }



  html,
  body {
    height: 100%;
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
      Meiryo, sans-serif;
    color: #222;
    -webkit-text-size-adjust: 100%;
  }

  img {
    max-width: 100%;
  }

  img,
  svg {
    vertical-align: bottom;
  }


  svg {
    height: auto;
  }

  main {
    display: block;
  }

  a,
  button {
    color: #666;
  }

  a,
  a::before,
  a::after,
  a *,
  button,
  button::before,
  button::after,
  button * {
    text-decoration: none;
  }

  html {
    font-size: 12px;
  }

  a:hover,
  button:hover {
    opacity: 0.7;
  }
html,body,#app,.wrapper,.container{
  background-color: #ffffff;
}

header,
footer,
main,
nav,
article,
aside,
section{
  display: block;
}


input,select{
    -webkit-appearance: none;
}

a, a::before, a::after, a *, button, button::before, button::after, button * {
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

a, button {
    color: #666;
}

input[type="text"], input[type="email"], input[type="button"], input[type="tel"], input[type="date"], input[type="search"], input[type="submit"], textarea, button, select {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 0;
    background: white;
    appearance: none;
}

select {
  appearance: menulist;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 3px;
}

input::placeholder {
  color: #ccc;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"] {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 4px 8px;
}


input[type="email"],
input[type="text"] {
  appearance: text;
  -webkit-appearance: text;
  -moz-appearance: text;
}

input[type="checkbox"] {
  appearance: checkbox;
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}

input[type="tel"] {
  appearance: tel;
  -webkit-appearance: tel;
  -moz-appearance: tel;
}

input[type="tel"] {
  appearance: tel;
  -webkit-appearance: tel;
  -moz-appearance: tel;
}

select {
  appearance: menulist;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 3px;
}

.u-select-wrapper {
  position: relative;
  background: #FFF;
  border: 1px solid #CCC;
  overflow: hidden;

  &:before {
    content:'';
    background-image: url('~@/assets/icon-sort-2-grey.png');
    background-size: cover;
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -9.5px;
    width: 20px;
    height: 19px;
  }
  & > select {
    padding: 0 0 0 10px;
    width: 100%;
    height: 100%;
    border: 0;
    cursor: pointer;
    appearance: none;
  }

  &.double-sort-arrow {
    &:before {
      background-image: url('~@/assets/icon-sort-4.png');
    }
  }
}

select {
  color: #707070
}
</style>
