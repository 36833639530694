<template>
  <div class="wrapper">
    <section id="my-page-section">
      <my-page-heading @updated="slideHeading" :heading="heading" />

      <p v-if="err.isErr" class="p-errmsg">
        <span>
          <img src="@/assets/attention.png" alt="エラー" width="22" height="22" />
        </span>
        {{ err.msg }}
      </p>

      <div id="c-my-page-content-wrapper">
        <my-page-top v-if="heading === 'top' && ready" :isOwner="userData.ownersClubId"/>
        <loading v-if="heading === 'mod' && !ready" />
        <my-page-modify-profile
          @updated="slideHeading"
          v-if="heading === 'mod' && ready"
          :userData="userData"
          :preflist="preflist"
          :limitedPrefList="limitedPrefList"
          :guidance="guidance"
          :triggerValidation="triggerValidation"
          @refresh-user-data="getUserData"
          @clearTrigger="triggerValidation = false"
          @clearOwnerMigrationGuidance="clearGuidance"
        />
        <my-page-modify-profile-complete @updated="slideHeading" v-if="heading === 'profile-complete'" />
        <my-page-change-password @updated="slideHeading" v-if="heading === 'pass' " :userData="userData" />
        <my-page-change-password-complete @updated="slideHeading" v-if="heading === 'password-complete'" />
      </div>
      <ConfirmationModal @close="clubModal = false" :modal="clubModal" header="ケイアイオーナーズクラブへようこそ">
        ケイアイ契約物件番号と、新しいご住所をご入力ください。
        <template v-slot:controls>
          <button @click="clubModal = false" class="modal-button">OK</button>
        </template>
      </ConfirmationModal>
      <ConfirmationModal @close="modal = false" :modal="modal" header="お客様情報の変更">
        新しいご住所をご入力ください
        <template v-slot:controls>
          <button @click="modal = false" class="modal-button">OK</button>
        </template>
      </ConfirmationModal>
    </section>

  </div>
</template>

<script>

import IsMobileMixin from '@/plugins/isMobile';
import MixinApi from '@/plugins/mixin-api';
import Meta from '@/assets/json/meta.json';

import MyPageHeading from '@/components/mypage/MyPageHeading';

import MyPageTop from '@/components/mypage/MyPageTop';

import MyPageChangePassword from '@/components/mypage/MyPageChangePassword';
import MyPageChangePasswordComplete from '@/components/mypage/MyPageChangePasswordComplete';

import MyPageModifyProfile from '@/components/mypage/MyPageModifyProfile';
import MyPageModifyProfileComplete from '@/components/mypage/MyPageModifyProfileComplete';
import ConfirmationModal from '@/components/common/ConfirmationModal';

import Loading from '@/components/common/Loading';
import { getUserAttributes } from '@/api/mypage';

const ONE_MAN_EN = 10000;

export default {
  name: 'MyPageIndex',
  mixins: [IsMobileMixin, MixinApi],
  components: {
    MyPageHeading,
    MyPageChangePassword,
    MyPageTop,
    Loading,
    MyPageModifyProfile,
    MyPageModifyProfileComplete,
    MyPageChangePasswordComplete,
    ConfirmationModal,
  },
  data() {
    return {
      heading: 'top',
      userData: {},
      err: {
        isErr: false,
        msg: '',
      },
      args: [],
      preflist: null,
      limitedPrefList: null,
      ready: false,
      modal: false,
      clubModal: false,
      guidance: {
        ownersClubMigration: false,
        ownersMigration: false,
      },
      triggerValidation: false
    };
  },
  // beforeCreate () {
    // if (this.$store.state.user === null) {
    //   location.href = '/';
    // }
  // },
  async mounted() {
    document.title = Meta.default.title;
    await this.getUserData();
    this.preflist = await this.getPrefectureList();
    this.limitedPrefList = await this.getPrefectureList(1);
    this.ready = true;

    // if there will be this kind of flag-related guidance in the future, this should be moved to somewhere more maintainable
    const user = this.userData;

    if (
      ( user.ownersClubMigrationGuidance ) ||
      (this.$route.query.ownersStatus === "join" && user.ownersStatus !== "join")
      ) {
      this.heading = "mod";
      this.guidance.ownersClubMigration = true;
      this.userData.tel = "";
      this.userData.home_tel = "";
      this.userData.zip = "";
      this.userData.prefecture = "";
      this.userData.address = "";
      this.userData.addressLocal = "";
      this.clubModal = true;
    } else if (
      ( user.ownersMigrationGuidance ) ||
      ( this.$route.query.enabledService === "ki_owner" && !user.enabledService.includes("ki_owner") )
      ) {
      this.heading = "mod";
      this.guidance.ownersMigration = true;
      this.userData.tel = "";
      this.userData.home_tel = "";
      this.userData.zip = "";
      this.userData.prefecture = "";
      this.userData.address = "";
      this.userData.addressLocal = "";
      this.modal = true;
    }
  },
  methods: {
    async getUserData() {
      this.err.isErr = false;
      this.err.msg = '';
      try {
        const userData = await getUserAttributes();

        this.userData = {};
        this.userData = {
          sei: userData.user_metadata.family_name,
          mei: userData.user_metadata.given_name,
          seikana: userData.user_metadata.phonetic_family_name,
          meikana: userData.user_metadata.phonetic_given_name,
          zip: userData.user_metadata.postal,
          address: userData.user_metadata.prefecture && userData.user_metadata.address ? userData.user_metadata.address : '',
          addressLocal: userData.user_metadata.building,
          email: userData.user_metadata.email,
          // remove prefix
          tel: userData.user_metadata.raw_phone_number ? `0${userData.user_metadata.raw_phone_number.slice(4, userData.user_metadata.raw_phone_number.length)}` : '',
          home_tel: userData.user_metadata.raw_home_phone_number ? `0${userData.user_metadata.raw_home_phone_number.slice(4, userData.user_metadata.raw_home_phone_number.length)}` : '',
          income: userData.user_metadata.household_income,
          budget: userData.user_metadata.budget ? userData.user_metadata.budget : '',
          desiredRent: userData.user_metadata.desired_rent ? userData.user_metadata.desired_rent : '',
          prefecture: userData.user_metadata.prefecture || '',
          area: userData.user_metadata.area || '',
          desiredMonthlyPrice: userData.user_metadata.desired_monthly_price ? userData.user_metadata.desired_monthly_price / ONE_MAN_EN : '',
          motiveForSearch: userData.user_metadata.motive_for_search || [],
          motiveForSearchAdditional: userData.user_metadata.motive_for_search_additional || '',
          desiredPriceRangeUpper: userData.user_metadata.desired_price_range_upper || 0,
          desiredPriceRangeLower: userData.user_metadata.desired_price_range_lower || 0,
          desiredKind: userData.user_metadata.desired_kind || [],
          desiredAreaPref: userData.user_metadata.desired_area_pref || '',
          desiredAreaCity: userData.user_metadata.desired_area_city || '',
          enabledService: userData.app_metadata.enabled_service || [],
          ownersClubId: userData.user_metadata.owners_club_id,
          ownersStatus: userData.user_metadata.owners_status,
          ownersClubMigrationGuidance: userData.user_metadata.owners_club_migration_guidance,
          ownersClubGuidance: userData.user_metadata.owners_club_guidance,
          propertyManagementNumber: userData.user_metadata.property_management_number || ''
        };
      } catch (e) {
        console.log(e);
        this.err.isErr = true;
        this.err.msg = 'ユーザー情報取得にエラーが発生しました。';
      }
    },
    triggerNestedValidation() {
      this.triggerValidation = true;
    },
    clearGuidance() {
      this.guidance.ownersClubMigration = false;
      this.guidance.ownersMigration = false;
    },
    slideHeading(heading) {
      if (this.guidance.ownersClubMigration || this.guidance.ownersMigration) {
        this.triggerNestedValidation();
        // user can't leave until they finish
        return;
      }
      this.heading = heading;
      this.getUserData();
    },
    verifyIncome(incomeValue) {
      const possibleValues = this.incomes.map(i => i.value.toString());
      if (!possibleValues.includes(incomeValue)) {
        return "";
      }
      return incomeValue;
    }
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@charset "UTF-8";
@media screen and (max-width: 1199px) {
  @import "../../assets/style/glocal_scss_sp.scss";
  @import "../../assets/style/parts_scss_sp.scss";
}
@media screen and (min-width: 1200px) {
  @import "../../assets/style/glocal_scss_pc.scss";
  @import "../../assets/style/parts_scss_pc.scss";
  body {
    margin: 0;
  }
}

.wrapper {
  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="email"] {
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    padding: 4px;
  }

  input[type="email"],
  input[type="text"] {
    appearance: text;
    -webkit-appearance: text;
    -moz-appearance: text;
  }

  input[type="checkbox"] {
    appearance: checkbox;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
  }

  input[type="tel"] {
    appearance: tel;
    -webkit-appearance: tel;
    -moz-appearance: tel;
  }

  input[type="tel"] {
    appearance: tel;
    -webkit-appearance: tel;
    -moz-appearance: tel;
  }

  select {
    appearance: menulist;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    padding: 3px;
  }
  }
  .wrapper {

    #my-page-section {
      width: 960px;
      margin: auto;
    }
}

#my-page-section ::v-deep .my-page-header {
  color: #222222;
  font-size: 16px;
  padding: 16px 0;
  text-align: center;
  margin: 0;
  line-height: 1em;
  font-weight: bold;
}

#my-page-section .submit-button {
  margin-top: 30px;
  background-color: red;
  width: 100%;
  color: white;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  height: 55px;
}


input {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-errmsg {
  display: flex;
  justify-content: center;
}

#c-my-page-content-wrapper {
  padding: 0 16px;
}

.modal-button {
  border: 1px solid #CCCCCC;
  border-radius: 26px;
  height: 48px;
  width: 160px;
  color: #E83532;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 999px) {
  .wrapper #my-page-section {
    width: 100%;
  }
}

@media (min-width: 770px) {
  #my-page-section ::v-deep .my-page-header {
    font-size: 18px;
  }
}


</style>
