<template>
  <div class="outer-wrapper">
    <h1 class="login-header">{{this.ownersStatus === "join" ? 'KEIAIオーナーズクラブ会員登録' : '新規会員登録'}}</h1>
    <div class="form-wrapper">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="email-field p-login-field">
            <div class="input-wrapper">
              <ValidatedInput :expandedTooltip="true" placeholder="メールアドレス" type="email" autocomplete="email" id="email" name="メールアドレス" v-model="email" rules="required|email"/>
            </div>
          </div>
          <p v-if="err.isErr" class="p-errmsg">
            {{err.msg}}
          </p>
          <common-agree-check v-model="agreeChecked" @clear="agreeTooltip = false" :error="agreeTooltip" :disabled="submitting" class="c-agree-check-wrapper">
            プライバシーポリシーとご利用規約に同意のうえ送信ボタンを押してください。
          </common-agree-check>
          <div class="policy-wrapper">
            <a href="https://ki-group.co.jp/policy" target="_blank" class="c-privacy-policy">
              <img id="my-page-icon" src="@/assets/icon-document.png" alt="">
              <p>プライバシーポリシー</p>
            </a>
            <a href="https://ki-group.co.jp/member/agreement/" target="_blank" class="c-contract">
              <img id="my-page-icon" src="@/assets/icon-document.png" alt="">
              <p>利用規約</p>
            </a>
          </div>
          <div>
            <input
              v-if="submitting"
              class="submit-button disabled-button round-button"
              type="submit"
              name="login"
              disabled
              value="送信中です・・・"
            />
            <input
              v-else-if="!agreeChecked"
              @click="showAgreeTooltip"
              type="button"
              class="submit-button disabled-button round-button"
              name="login"
              value="上記に同意して仮登録メールを送信"
            />
            <input
              v-else
              class="submit-button round-button"
              type="submit"
              name="login"
              value="上記に同意して仮登録メールを送信"
            />
          </div>
          <div class="p-form p-form--single p-mail-warning">
            <div class="center-wrapper">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              <p>
              メール受信制限をされている方は、「ki-group.jp」からの受信許可設定をしてください。
              </p>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <ConfirmationModal @close="modal = false" :modal="modal" header="このメールアドレスはすでに登録されています">
        入力されたメールアドレスは登録済みです。ログインして新しいご住所をご登録ください。
        <template v-slot:controls>
          <button @click="navigateToLogin" class="modal-button">ログイン画面へ</button>
        </template>
      </ConfirmationModal>
      <ConfirmationModal @close="clubModal = false" :modal="clubModal" header="このメールアドレスはすでに登録されています">
        入力されたメールアドレスは登録済みです。ログインして契約物件番号をご登録ください。
        <template v-slot:controls>
          <button @click="navigateToLogin" class="modal-button">ログイン画面へ</button>
        </template>
      </ConfirmationModal>
    </div>

    <a @click="navigateToLogin" id="go-login-wrapper">
      <img class="my-page-icon" src="@/assets/icon-login.png" alt="">
      <p class="c-go-login">会員登録済みの方はこちらからログイン</p>
    </a>
  </div>
</template>

<script>
import { parseErrorCode, signUp } from '@/utils/auth';
import ValidatedInput from "@/components/common/validation/ValidatedInput";
import { ValidationObserver } from 'vee-validate';
import CommonAgreeMixin from "@/plugins/component-mixins/agree-check.js";
import ConfirmationModal from '@/components/common/ConfirmationModal';

export default {
  name: 'MemberRegist',
  mixins: [CommonAgreeMixin],
  components: {
    ValidatedInput,
    ValidationObserver,
    ConfirmationModal,
  },
  data() {
    return {
      err: {
        isErr: false,
        msg: '',
      },
      email: '',
      submitting: false,
      enabledService: [],
      modal: false,
      clubModal: false,
      ownersStatus: null,
      kindOfMemberShopId: null,
      agreeChecked: false
    };
  },
  methods: {
    navigateToForm() {
      this.$emit('updated', 'form');
    },
    navigateToSent() {
      this.$emit('updated', 'sent');
    },
    navigateToLogin() {
      this.$router.push("/login");
    },
    async onSubmit() {
      if (!this.agreeChecked) {
        return;
      }
      this.err.isErr = false;
      this.err.msg = '';
      try {
        this.submitting = true;
        await signUp(this.email.trim(), this.enabledService, this.ownersStatus, this.kindOfMemberShopId);
        this.navigateToSent();
      } catch (e) {
        const errorCode = parseErrorCode(e);
        if (errorCode === 'user_exists') {
          this.err.msg = 'このメールアドレスはすでに登録されています。';
        } else if (errorCode === 'user_exists_owner') {
          this.modal = true;
        } else if (errorCode === 'user_exists_owners_club') {
          this.clubModal = true;
        } else {
          this.err.msg = 'エラーが発生しました。しばらく待ってからやり直してください。';
        }
        this.err.isErr = true;
      } finally {
        this.submitting = false;
      }
    },
  },
  created() {
    // if (this.$route.query.signoutSSO) {
    //   signOutAllServices();
    // }
    if (this.$store.state.user) {
      this.navigateToForm();
    }
    if (this.$route.query.code && this.$route.query.email) {
      this.navigateToForm();
    }
    if (this.$route.query.enabledService) {
      this.enabledService = this.$route.query.enabledService.split(",").map(a => a.trim());
    }
    if (this.$route.query.ownersStatus) {
      this.ownersStatus = this.$route.query.ownersStatus;
    }
    if (this.$route.query.kindOfMemberShopid) {
      this.kindOfMemberShopId = this.$route.query.kindOfMemberShopid;
    }
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@charset "UTF-8";
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import "../../../assets/style/parts_responsive.scss";

.login-header {
  line-height: 28px;
}

.form-wrapper {
  padding: 16px 12px;
}

.outer-wrapper {
  padding-bottom: 30px;
}

.c-go-login {
  margin-left: 8px;
  text-align: right;
  font-size: 14px;
}

.p-mail-warning {
  display: flex;
  margin-top: 34px;
  line-height:22px;
  justify-content: center;

  p {
    font-size: 12px;
  }

  .center-wrapper {
    display: flex;
  }

  .fa-exclamation-triangle {
    margin-right: 12px;
    font-size: 20px;
    color: #ffcc00;
  }
}

label {
  font-size: 14px;
}

.c-agree-check-wrapper {
  margin: 32px 0 24px;
}

.p-standard-field {
  max-width: 492px;
}

.policy-wrapper {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 300px;
}

.c-contract,
.c-privacy-policy {
  font-size: 14px;
  padding-left: 4px;
  margin-top: 8px;
  display: flex;
  align-items: center;

  p {
    margin-left: 4px;
  }
}

.submit-button {
  margin-top: 28px;
  font-size: 16px;
}

#go-login-wrapper {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 14px;
  width: 299px;
  font-size: 14px;

  .my-page-icon {
    height:28px;
    width:28px;
  }
}

.modal-button {
  border: 1px solid #CCCCCC;
  border-radius: 26px;
  height: 48px;
  width: 256px;
  color: #E83532;
  font-size: 16px;
  font-weight: bold;
}

@media(min-width: 640px) {
  .email-field {
    margin: auto;
    width: 360px;
  }

  .outer-wrapper {
    width: 612px;
    margin:auto;
  }

  .p-standard-field label {
    width: 26%;
  }

  .underline-header {
    text-align: left;
  }

  .intro-text {
    font-size: 18px;
  }

  #go-login-wrapper {
    margin-top: 10px;
  }
}

@media(min-width: 1000px) {

}
</style>
