<template>
  <div>
    <Loading v-if="!isReady" />
    <div v-else id="regist-wrapper">
      <member-regist-form
        v-show="pages === 'form'"
        :userJoiningOwners="userJoiningOwners"
        :userJoiningOwnersClub="userJoiningOwnersClub"
        :preflist="preflist"
        :limitedPrefList="limitedPrefList"
        @updated="slideView"
      />
      <member-regist-confirm
        v-if="pages === 'confirm'"
        :userJoiningOwners="userJoiningOwners"
        :userJoiningOwnersClub="userJoiningOwnersClub"
        :args="this.args"
        @updated="slideView"
      />
      <member-regist-complete v-if="pages === 'complete'" />

    </div>
  </div>
</template>

<script>
import IsMobileMixin from '@/plugins/isMobile';

import Meta from '@/assets/json/meta.json';
import MixinAPI from '@/plugins/mixin-api';

import Loading from '@/components/common/Loading';

import MemberRegistForm from '@/components/member/regist/MemberRegistForm';
import MemberRegistConfirm from '@/components/member/regist/MemberRegistConfirm';
import MemberRegistComplete from '@/components/member/regist/MemberRegistComplete';

export default {
  name: 'Regist',
  components: {
    MemberRegistForm,
    MemberRegistConfirm,
    MemberRegistComplete,
    Loading,
  },
  data() {
    return {
      pages: 'form',
      args: [],
      preflist: [],
      userEnabledServices: null,
      limitedPrefList: [],
      isReady: false,
    };
  },
  async created() {
    if (!(this.$route.query.code && this.$route.query.username)) {
      this.$router.replace({path: '/member/signup', query: this.$route.query});
    } else {
      this.userEnabledServices = this.$route.query.es.split(',');
      this.ownersStatus = this.$route.query.os;
      this.isReady = true;
    }
  },
  async mounted() {
    document.title = Meta.default.title;
    this.preflist = await this.getPrefectureList();
    this.limitedPrefList = await this.getPrefectureList(1);
  },
  mixins: [IsMobileMixin, MixinAPI],
  computed: {
    // user only joining owners
    userJoiningOwners() {
      return this.userEnabledServices && this.userEnabledServices.includes("ki_owner"); 
    },
    // user only joining owners and owners club
    userJoiningOwnersClub() {
      return this.ownersStatus === "join";
    },
  },
  methods: {
    slideView(nextView, args = []) {
      this.pages = nextView;
      this.args = args;
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@charset "UTF-8";
@media screen and (max-width: 1199px) {
  @import "../../assets/style/glocal_scss_sp.scss";
  @import "../../assets/style/parts_scss_sp.scss";
}
@media screen and (min-width: 1200px) {
  @import "../../assets/style/glocal_scss_pc.scss";
  @import "../../assets/style/parts_scss_pc.scss";
  body {
    margin: 0;
  }
}
@import "../../assets/style/parts_responsive.scss";

@media screen and (min-width: 640px) {
  #regist-wrapper {
    padding: 30px 15px;
    margin: auto;
  }
}

@media (min-width: 1000px) {
  #regist-wrapper {
    width: 100%;
  }

  #profile-change-wrapper {
    padding: 20px;

    #profile-change-inner-wrapper {
      width: 800px;
    }
  }

  #profile-change-form {
    .profile-change-field {

      label {
        width: 40%;
      }
      .input-wrapper {
        width: 60%;
        #address-select-wrapper {
          width: 100%;
        }

        &.name-input-wrapper {
          .inner-input-wrapper {
            flex: 1;
          }
          .top-margin {
            margin-top: 0;
            margin-left: 8px;
          }
        }
      }
      #desired-rent-input-wrapper {
        width: 60%;
      }
    }
  }

  .inner-input-wrapper {
    width: 100%;
    flex: 1;
  }

  .inner-input-wrapper-fullscreen-short {
    width: 48%;
  }
}

</style>
