<template>
  <div id="password-change-wrapper">
    <h2 class="my-page-header">パスワードの変更</h2>
    <p id="password-complete-message">パスワードの変更が完了しました。</p>
    <div class="button-wrapper">
      <button @click="navigateToTop" class="submit-button top-button round-button">
        マイページへ
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangePasswordComplete',
  methods: {
    navigateToTop() {
      this.$emit('updated', 'top');
    }
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/style/parts_responsive.scss";

#password-complete-message {
  text-align: center;
  font-size: 16px;
  padding: 30px 0;
}

.submit-button {
  margin-top: 12px;
}

</style>
