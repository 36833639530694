export function retry(func, onError) {
  function _retry(e) {
    return onError(e)
      .catch((e) => {
        throw e
      })
      .then(func)
      .catch(_retry)
  }
  return func().catch(_retry)
}
export function sleep(msec) {
  return new Promise((resolve) => window.setTimeout(resolve, msec))
}