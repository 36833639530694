<template>
  <div id="profile-change-wrapper">
    <h2 class="my-page-header">ご登録内容の変更</h2>
    <div id="profile-change-inner-wrapper">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form id="profile-change-form" @submit.prevent="handleSubmit(onSubmit)">
          <div v-if="isOwner" class="profile-change-field center-align">
            <label class="p-form-title" for="email">契約物件番号<span class="required">必須</span></label>
            <div class="input-wrapper">
              {{ form.propertyManagementNumber }}
            </div>
          </div>
          <div class="profile-change-field align-start">
            <label class="p-form-title" for="name">お名前<span class="required">必須</span></label>
            <div class="input-wrapper name-input-wrapper">
              <ValidatedInput
                v-model="form.sei" placeholder="姓" type="text" name="姓" id="family-name" rules="required" class="inner-input-wrapper" autocomplete="family-name"
              />
              <ValidatedInput
                v-model="form.mei" placeholder="名" type="text" name="名" id="given-name" rules="required" class="inner-input-wrapper top-margin" autocomplete="given-name"
              />
            </div>
          </div>
          <div class="profile-change-field align-start">
            <label class="p-form-title" for="name">フリガナ<span class="required">必須</span></label>
            <div class="input-wrapper name-input-wrapper">
              <ValidatedInput
                v-model="form.seikana" placeholder="セイ" class="inner-input-wrapper" type="text" name="セイ" id="phonetic-family-name" rules="required"
              />
              <ValidatedInput
                v-model="form.meikana" placeholder="メイ" class="top-margin inner-input-wrapper" type="text" name="メイ" id="phonetic-given-name" rules="required"
              />
            </div>
          </div>
          <div class="profile-change-field align-start">
            <label class="p-form-title" for="addresslocal">住所<span class="required">必須</span></label>
            <div class="input-wrapper">
              <ValidatedZipField
                v-model="form.zip" type="text" name="郵便番号" id="zip" rules="required|zip" :expandedTooltip="true" class="inner-input-wrapper"
              />
              <PrefCitySelect :prefectureValue="form.prefecture" :cityValue="form.address" @input-prefecture="form.prefecture = $event" @input-city="form.address = $event" :prefectureList="preflist" @initialized="setInitialCity" ref="prefCitySelect"/>
              <ValidatedInput
                v-model="form.addressLocal" placeholder="町名・番地・建物名（末尾まで入力）" class="top-margin inner-input-wrapper-long" type="text" name="住所" rules="required"
              />
            </div>
          </div>
          <div class="profile-change-field">
            <label class="p-form-title" for="tel">電話番号（携帯）<span class="required">必須</span></label>
            <div class="input-wrapper">
              <ValidatedTelephoneField
                @input="form.tel = $event" :value="form.tel" type="tel" name="電話番号（携帯）" id="tel" :expandedTooltip="true" rules="required|phone" class="inner-input-wrapper"
              />
            </div>
          </div>
          <div class="profile-change-field">
            <label class="p-form-title" for="tel">電話番号（自宅）<span class="optional">任意</span></label>
            <div class="input-wrapper">
              <ValidatedTelephoneField
                @input="form.home_tel = $event" :value="form.home_tel" type="tel" name="電話番号（自宅）" id="home_tel" :expandedTooltip="true" rules="phone" class="inner-input-wrapper" :placeholder="true"
              />
            </div>
          </div>
          <div class="profile-change-field center-align">
            <label class="p-form-title" for="email">メールアドレス<span class="required">必須</span></label>
            <div class="input-wrapper">
              <!-- <ValidatedInput
                v-model="form.email" placeholder="ki-group@xxxx.com" type="email" name="メールアドレス" id="email" :expandedTooltip="true" rules="required|email" class="inner-input-wrapper"
              /> -->
              {{userEmail}}
            </div>
          </div>
          <div v-if="!isOwner && !guidance.ownersClubMigration && !guidance.ownersMigration">
            <div class="profile-change-field align-start">
              <label class="p-form-title" for="desiredKind">希望の物件種別 <span class="c-label-subtitle">(複数回答可）</span><span class="required">必須</span></label>
              <validation-wrapper ref="prov" class="input-wrapper" name="希望の物件種別" rules="required" :expandedTooltip="false" :tooltipPositionBottom="48">
                <div class="c-check-wrapper">
                  <input v-model="form.desiredKind" type="checkbox" value="新築戸建て" id="新築戸建て">
                  <label for="新築戸建て" class="c-checkbox-label"><span class="check-icon"></span>新築戸建て</label>
                </div>
                <div class="c-check-wrapper">
                  <input v-model="form.desiredKind" type="checkbox" value="中古戸建て" id="中古戸建て">
                  <label for="中古戸建て" class="c-checkbox-label"><span class="check-icon"></span>中古戸建て</label>
                </div>
                <div class="c-check-wrapper">
                  <input v-model="form.desiredKind" type="checkbox" value="新築マンション" id="新築マンション">
                  <label for="新築マンション" class="c-checkbox-label"><span class="check-icon"></span>新築マンション</label>
                </div>
                <div class="c-check-wrapper">
                  <input v-model="form.desiredKind" type="checkbox" value="中古マンション" id="中古マンション">
                  <label for="中古マンション" class="c-checkbox-label"><span class="check-icon"></span>中古マンション</label>
                </div>
                <div class="c-check-wrapper">
                  <input v-model="form.desiredKind" type="checkbox" value="土地" id="土地">
                  <label for="土地" class="c-checkbox-label"><span class="check-icon"></span>土地</label>
                </div>
              </validation-wrapper>
            </div>
            <div class="profile-change-field" id="desired-area-field">
              <label class="p-form-title" for="income">希望エリア<span class="required">必須</span></label>
              <div class="input-wrapper">
                <PrefCitySelect :prefectureValue="form.desiredAreaPref" :cityValue="form.desiredAreaCity" @input-prefecture="form.desiredAreaPref = $event" @input-city="form.desiredAreaCity = $event" :prefectureList="preflist" @initialized="setInitialDesiredCity"/>
              </div>
            </div>
            <div class="profile-change-field">
              <label class="p-form-title" for="income">希望価格帯<span class="required">必須</span></label>
              <div class="input-wrapper">
                <div class="c-price-select-inner-input-wrapper">
                  <ValidatedSelect class="select-wrapper inner-input-wrapper" :items="priceRangesLower" :doubleSort="true" rules="required|lessThan:@upper" name="ご希望の価格帯" v-model="form.desiredPriceRangeLower" id="other-budget" valueKey="value" nameKey="name" />
                </div>
                <span class="c-price-separator"> ~ </span>
                <div class="c-price-select-inner-input-wrapper">
                  <ValidatedSelect class="select-wrapper inner-input-wrapper" :items="priceRangesUpper" :doubleSort="true" rules="required" name="ご希望の価格帯" vid="upper" v-model="form.desiredPriceRangeUpper" id="other-budget" valueKey="value" nameKey="name" />
                </div>
              </div>
            </div>
            <div class="profile-change-field">
              <label class="p-form-title" for="desired-monthly-price">月々の支払想定額<span class="optional">任意</span></label>
              <div class="standard-field-input-wrapper">
                <div class="inner-input-wrapper c-input-finance-field-width">
                  <ValidatedInput
                  v-model="form.desiredMonthlyPrice" placeholder="10" type="text" name="desired-monthly-price" id="desired-monthly-price" rules="numeric" tooltipArrowPosition="left"
                  />
                  <span class="input-suffix">万円</span>
                </div>
              </div>
            </div>
            <div class="profile-change-field align-start">
              <label class="p-form-title" for="motiveForSearch">購入動機 <span class="c-label-subtitle-motive">(複数回答可）</span><span class="required">必須</span></label>
              <validation-wrapper ref="prov" class="input-wrapper" name="購入動機" rules="required" :expandedTooltip="false" :tooltipPositionBottom="48">
                <div class="c-check-wrapper">
                  <input v-model="form.motiveForSearch" type="checkbox" value="ご結婚" id="ご結婚">
                  <label for="ご結婚" class="c-checkbox-label"><span class="check-icon"></span>ご結婚</label>
                </div>
                <div class="c-check-wrapper">
                  <input v-model="form.motiveForSearch" type="checkbox" value="ご出産" id="ご出産">
                  <label for="ご出産" class="c-checkbox-label"><span class="check-icon"></span>ご出産</label>
                </div>
                <div class="c-check-wrapper">
                  <input v-model="form.motiveForSearch" type="checkbox" value="お子様のご進学" id="お子様のご進学">
                  <label for="お子様のご進学" class="c-checkbox-label"><span class="check-icon"></span>お子様のご進学</label>
                </div>
                <div class="c-check-wrapper">
                  <input v-model="form.motiveForSearch" type="checkbox" value="手狭になった" id="手狭になった">
                  <label for="手狭になった" class="c-checkbox-label"><span class="check-icon"></span>手狭になった</label>
                </div>
                <div class="c-check-wrapper">
                  <input v-model="form.motiveForSearch" type="checkbox" value="その他" id="その他">
                  <label for="その他" class="c-checkbox-label"><span class="check-icon"></span>その他（下記にご入力ください）</label>
                </div>
                <div class="inner-input-wrapper">
                  <ValidatedInput
                    v-model="form.motiveForSearchAdditional"
                    :disabled="!form.motiveForSearch.includes('その他')"
                    placeholder=""
                    type="text"
                    name="きっかけ"
                    id="current-rent"
                    :rules="form.motiveForSearch.includes('その他') ? 'required' : ''"
                  />
                </div>
              </validation-wrapper>
            </div>
          </div>
          <p v-if="err.isErr" class="p-errmsg">
          <span>
            <img src="@/assets/attention.png" alt="エラー" width="22" height="22" />
          </span>
          {{ err.msg }}
          </p>
          <input
            v-if="submitting"
            disabled
            type="submit"
            name="ユーザ情報変更"
            value="変更中です・・・"
            class="submit-button disabled-button round-button"
            />
          <input
            v-else
            type="submit"
            name="ユーザ情報変更"
            value="変更する"
            class="submit-button round-button"
            />
          <div class="c-goto-top-button-wrapper">
            <input
              v-if="isMobile"
              type="button"
              name="ユーザ情報変更"
              value="ページTOPへ"
              class="revise-button round-button"
              @click="scrollToTop"
              />
          </div>
        </form>
      </ValidationObserver>
      <ConfirmationModal @close="modal = false" @submit="finish" :modal="modal" header="お客様情報の変更">
        お客様情報を変更してもよろしいでしょうか？
      </ConfirmationModal>
    </div>
  </div>

</template>

<script>
import { Core as YubinBangoCore } from 'yubinbango-core'
import IsMobile from '@/plugins/isMobile';
import { updateUserAttributes } from '@/utils/auth';
import { confirmOwner } from '@/api/registration';
import { ValidationObserver } from 'vee-validate';
import MixinApi from '@/plugins/mixin-api';
import PrefCitySelect from '@/components/common/PrefCitySelect';
import ValidationWrapper from '@/components/common/validation/ValidationWrapper';
import ValidatedInput from '@/components/common/validation/ValidatedInput';
import ValidatedTelephoneField from '@/components/common/validation/telephone/ValidatedTelephoneField';
import ValidatedSelect from '@/components/common/validation/ValidatedSelect';
import ValidatedZipField from '@/components/common/validation/zip/ValidatedZipField';
import ConfirmationModal from '@/components/common/ConfirmationModal';

const priceRangesLower = require('@/utils/price-data/desiredPriceRangesLower');
const priceRangesUpper = require('@/utils/price-data/desiredPriceRangesUpper');

const ONE_MAN_YEN = 10000;

export default {
  name: 'MypageModifyProfile',
  props: ['userData', 'preflist', 'incomes', 'budgets', 'limitedPrefList', 'guidance', 'triggerValidation'],
  mixins: [MixinApi, IsMobile],
  components: { ValidationObserver, ValidatedInput, ConfirmationModal, ValidatedTelephoneField, ValidatedZipField, PrefCitySelect, ValidationWrapper, ValidatedSelect },
  data() {
    return {
      err: {
        isErr: false,
        msg: '',
      },
      priceRangesLower,
      priceRangesUpper,
      form: null,
      modal: false,
      submitting: false,
      citiesLoading: false,
      cityList: null,
      agreement: false,
    };
  },
  created() {
    this.form = { ...this.userData };
  },
  async mounted(){
    if (this.userData.prefecture) {
      await this.getCities(this.userData.prefecture);
      this.form.address = this.userData.address;
    }
  },
  methods: {
    async getCities(pref_name) {
      try {
        this.form.address = "";
        this.citiesLoading = true;
        this.cityList = null;
        const prefecture = this.preflist.find(item => item.pref_name === pref_name);
        this.cityList = await this.getCityList(prefecture.pref_code)
      } catch(e) {
        console.log(e);
      } finally {
        this.citiesLoading = false;
      }
    },
    setInitialCity() {
      this.form.address = this.userData.address;
    },
    setInitialDesiredCity() {
      this.form.desiredAreaCity = this.userData.desiredAreaCity;
    },
    onSubmit() {
      if (this.submitting) {
        return;
      }
      this.modal = true;
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    async finish() {
      if (this.submitting) {
        return;
      }
      this.modal = false;
      this.err.isErr = false;
      this.err.msg = '';
      try {
        this.submitting = true;
        const newAttributes = {
          family_name: this.form.sei,
          given_name: this.form.mei,
          phonetic_family_name: this.form.seikana,
          phonetic_given_name: this.form.meikana,
          postal: this.form.zip,
          address: this.form.address,
          building: this.form.addressLocal,
          phone_number: this.buildPhoneNumber(this.form.tel),
          home_phone: this.form.home_tel ? this.buildPhoneNumber(this.form.home_tel) : null,
          prefecture: this.form.prefecture,
          raw_phone_number: this.buildRawPhoneNumber(this.form.tel),
          raw_home_phone_number: this.form.home_tel ? this.buildRawPhoneNumber(this.form.home_tel) : null,
          desired_monthly_price: this.form.desiredMonthlyPrice ? +this.form.desiredMonthlyPrice * ONE_MAN_YEN : '',
          motive_for_search: this.form.motiveForSearch,
          motive_for_search_additional: this.form.motiveForSearchAdditional,
          desired_price_range_upper: +this.form.desiredPriceRangeUpper,
          desired_price_range_lower: +this.form.desiredPriceRangeLower,
          desired_kind: this.form.desiredKind,
          desired_area_pref: this.form.desiredAreaPref,
          desired_area_city: this.form.desiredAreaCity,
        };

        // if (this.guidance.ownersClubMigration) {
        //   newAttributes.owners_club_id = this.form.ownersClubId;
        // }

        await updateUserAttributes(this.$store.state.user.username, newAttributes);
        this.$emit('refresh-user-data');

        if (this.guidance.ownersClubMigration) {
          await confirmOwner({
            userId: this.$store.state.user.username,
            ownersClubId: this.form.ownersClubId,
            ownersStatus: "join"
          })
          this.$emit("clearOwnerMigrationGuidance");
        } else if (this.guidance.ownersMigration) {
          await confirmOwner({
            userId: this.$store.state.user.username
          })
          this.$emit("clearOwnerMigrationGuidance");
        }
        this.navigateComplete();
      } catch(e) {
        console.log(e);
        this.err.isErr = true;
        this.handleError(e);
      } finally {
        this.submitting = false;
      }
    },
    navigateComplete() {
      this.$emit('updated', 'profile-complete');
    },
    buildRawPhoneNumber(phone) {
      let temp = phone[0] === "0" ? phone.replace("0", "") : phone;
      return "+81-" + temp;
    },
    buildPhoneNumber(phone) {
      let temp = phone[0] === "0" ? phone.replace("0", "") : phone;
      const split = temp.split("-");
      const joined = split.join("");
      return "+81" + joined;
    },
    handleError(e) {
      if ( e.code === 'InvalidParameterException' ) {
        this.err.msg = '正しい形式のパスワードを入力してください';
      } else if (e.code === 'NotAuthorizedException') {
        this.err.msg = '現在の正しいパスワードを入力してください。';
      } else if (e.code === 'phone_number_in_use') {
        this.err.msg = '電話番号 (携帯) はすでに登録されています。';
      } else {
        this.err.msg = 'エラーが発生しました。'
      }
    },
    yubinBango() {
      let _this = this
      new YubinBangoCore(this.form.zip, function(addr) {
        _this.form.prefecture  = addr.region
        _this.$refs.prefCitySelect.getCities(addr.region)
        _this.form.address = addr.locality
        if(_this.form.addressLocal.indexOf(addr.street)) {
          _this.form.addressLocal = addr.street
        }
      })
    }
  },
  watch: {
  // eslint-disable-next-line func-names
    'form.motiveForSearch': function (newValue) {
      if (!newValue.includes('その他')) {
        this.form.motiveForSearchAdditional = '';
      }
    },
    async triggerValidation() {
      if (this.triggerValidation) {
        await this.$refs.observer.validate();
        this.$emit("clearTrigger");
      }
    },
    'form.zip': function() {
      const zipRegexp = /^\d{3}-?\d{4}$/
      if(!this.form.zip.match(zipRegexp)) {
        return;
      }
      this.yubinBango()
    },
  },
  computed: {
    userEmail() {
      return this.$store.state.user.attributes.email;
    },
    userClubId() {
      return this.userData.ownersClubId;
    },
    isOwner() {
      return this.userData.enabledService.includes("ki_owner");
    },
    isOwnersClub() {
      return this.userData.ownersStatus === "join"
    },
    verifiedCity() {
      if (!this.cityList) {
        return "";
      }
      const possibleValues = this.cityList.map(c => c.city_name)
      if (!possibleValues.includes(this.form.address)) {
        return "";
      }
      return this.form.address;
    }
  }
};
</script>
<style scoped lang="scss">
@charset "UTF-8";
@import "../../assets/style/parts_responsive.scss";

#profile-change-form {

  .profile-change-field {
    padding: 12px 0;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;

    ::v-deep input,
    ::v-deep select {
      width: 100%;
      margin-top: 0px;
      height: 34px;
    }

    .c-check-wrapper {
      position: relative;
      margin-top: 9px;
      margin-right: 22px;
      margin-bottom: 10px;
    }

    input[type=checkbox] {
      display: none;
    }

    input[type=checkbox]:checked + label > .check-icon {
      background-image: url(../../assets/checkbox-rounded-checked@2x.png);
      background-repeat: no-repeat;
      background-size: 100%;
      height: 24px;
      width: 24px;
      position: absolute;
      bottom: 2px;
      left: 0;
      cursor: pointer;
    }

    input[type=checkbox] + label > .check-icon {
      background-image: url(../../assets/checkbox-rounded-unchecked@2x.png);
      background-size: 86%;
      background-repeat: no-repeat;
      height: 24px;
      width: 24px;
      position: absolute;
      bottom: 0px;
      bottom: 1px;
      left: 0;
      cursor: pointer;
    }

    .c-checkbox-label {
      font-weight: 400;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 100%;
      margin-left: 32px;
      padding-bottom: 6px;
      font-size: 14px;
      cursor: pointer;
    }

    .input-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;


      .address-select-wrapper {
        display: flex;
        margin-top: 10px;
        width: 100%;

        ::v-deep .select-wrapper{
          flex: 1
        }
        ::v-deep .select-wrapper:first-child {
          margin-right: 8px;
        }
        ::v-deep select {
          width: 100%;
        }

      }
    }
    .c-price-select-inner-input-wrapper {
      width: 158px;
    }
    .c-input-finance-field-width {
      position: relative;
      width: 94px;
    }
    label {
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      height: 24px;
      span {
        bottom: 4px;
        &.required {
          margin-left: 1em;
          padding: 6px;
          font-size: 12px;
          color: white;
          background-color: red;
          position: relative;
          bottom: 2px;
        }
        &.optional {
          margin-left: 1em;
          padding: 6px;
          font-size: 12px;
          color: white;
          background-color: #707070;
          position: relative;
          bottom: 2px;
        }
      }
    }
  }

  .double-input {
    input {
      flex: 1;
      margin-right: 8px;
    }

  }
}

.input-suffix {
  position: absolute;
  top: 25%;
  right: -38px;
}

.p-paragraph--small {
  text-align: center;
}

.p-h2 {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  margin:0;
  line-height: 1em;
}

.top-margin {
  margin-top: 8px;
}

.inner-input-wrapper,
.inner-input-wrapper-long {
  width: 100%;
}

.standard-field-input-wrapper {
  width: 54%;
  .inner-input-wrapper {
    position: relative;
  }
}

.c-price-separator {
  font-size: 20px;
  color: #555555;
  display: block;
  padding: 8px;
  display: flex;
  margin: auto 0;
}

#desired-area-field {
  ::v-deep .address-select-wrapper {
    margin-top: 0px !important;
  }
}

@media (min-width: 640px) {
  #profile-change-wrapper {
    width: 760px;
    margin: auto;

    #profile-change-inner-wrapper {
      margin: auto;
      width: 94%;
    }
  }

  #profile-change-form {
    .profile-change-field {
      align-items: center;

      padding: 6px 0;

      &.align-start {
        align-items: start;
      }
      input,
      select {
        width: 100%;
      }
      .input-wrapper {
        width: 54%;
        input {
          width: 100%;
        }
        #address-select-wrapper {
          width: 75%;
        }
      }
      #desired-rent-input-wrapper {
        width: 54%;
      }
      label {
        display: flex;
        justify-content: space-between;
        padding-right: 24px;
        height: 100%;
        width: 42%;
        text-align: left;
        margin-top: 8px;
      }
    }
  }

  .inner-input-wrapper {
    width: 75%;
  }

}

.c-goto-top-button-wrapper {
  width: 222px;
  margin: 30px auto;

  input {
    height: 41px;
    font-size: 14px;
  }
}


</style>
