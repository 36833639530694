<template>
  <footer class="l-footer">
    <div class="l-footer__links">
      <ul class="l-footer__list">
        <li class="l-footer__item">
          <a class="c-link" target="_blank" href="https://ki-group.co.jp/policy/">プライバシーポリシー</a>
          <img class="c-link-icon" src="@/assets/resources/external-link.png" alt="">
        </li>
        <li class="l-footer__item">
          <a class="c-link" target="_blank" href="https://ki-group.co.jp/member/agreement/">利用規約</a>
          <img class="c-link-icon" src="@/assets/resources/external-link.png" alt="">
        </li>
        <li v-if="!isMobile" class="l-footer__item">
          <a class="c-link" target="_blank" href="https://ki-group.co.jp/policy/antisocial/">反社会的勢力の排除に関する基本方針</a>
          <img class="c-link-icon" src="@/assets/resources/external-link.png" alt="">
        </li>
        <li v-if="!isMobile" class="l-footer__item">
          <a class="c-link" target="_blank" href="https://ki-group.co.jp/policy/safety/">安全衛生基本方針</a>
          <img class="c-link-icon" src="@/assets/resources/external-link.png" alt="">
        </li>
        <li v-if="!isMobile" class="l-footer__item">
          <a class="c-link" target="_blank" href="https://ki-group.co.jp/policy/compliance/">コンプライアンス基本方針</a>
          <img class="c-link-icon" src="@/assets/resources/external-link.png" alt="">
        </li>
        <li v-if="!isMobile" class="l-footer__item">
          <a class="c-link" target="_blank" href="https://ki-group.co.jp/policy/mynumber/">特定個人情報等の適正な取扱い基本方針</a>
          <img class="c-link-icon" src="@/assets/resources/external-link.png" alt="">
        </li>
        <li v-if="!isMobile" class="l-footer__item">
          <a class="c-link" target="_blank" href="https://ki-group.co.jp/policy/social/">ソーシャルメディアポリシー</a>
          <img class="c-link-icon" src="@/assets/resources/external-link.png" alt="">
        </li>
      </ul>
    </div>
    <div class="l-footer__bottoms">
      <small class="l-footer__copyright">Copyright &copy; ケイアイスター不動産 All Rights Reserved.</small>
    </div>
  </footer>
</template>


<script>
import isMobile from '@/plugins/isMobile';

export default {
  name: 'CommonFooter',
  mixins: [isMobile],
  data() {
    return {
    };
  },
};
</script>

<style scoped lang='scss'>
@charset "UTF-8";

.l-footer {
  width: 100%;
  padding: 20px 0px 48px;
  border-top: 1px solid #ccc;
  background-color:#EFEFEF;

  &__links {
    max-width: 820px;
    margin: 0 auto 24px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 0px;

    .l-footer__item {
      padding: 8px 12px;
      display: flex; 
      align-items: center;

      a {
        font-size: 12px;
      }

      .c-link-icon {
        margin-left: 4px;
        width: 16px;
        height: 16px;
      }
    }
  }
  &__link {
    color: #666;
    font-size: 12px;
  }
  &__bottoms {
    padding: 8px 20px;
    text-align: center;
  }
  &__copyright {
    color: #666;
    font-size: 12px;
  }
}

@media (min-width: 325px) {
  .l-footer {
    &__list {
      padding: 0px 20px;
    }
  }
}


@media (min-width: 420px) {
  .l-footer {
    &__list {
      padding: 0px 60px;
    }
  }
}

@media(min-width: 712px) {
  .l-footer {
    &__list {
      justify-content: center;
      padding: 0px 0px;
    }
  }
}

@media(min-width: 1000px) {
  .l-footer {

    &__list {
      justify-content: flex-start;
      padding: 0px 40px;
    }

    &__links {
      max-width: 1020px;
    }
  }
}


</style>
