export default {
  data() {
    return {
      isMobile: true,
      isTablet: true,
    };
  },
  created() {
    this.isMobile = window.innerWidth < 712;
    this.isTablet = window.innerWidth < 999;
  },
  mounted() {
    window.addEventListener('resize', this.calcWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcWidth);
  },
  methods: {
    calcWidth() {
      this.isMobile = window.innerWidth < 712;
      this.isTablet = window.innerWidth < 999;
    },
  },
};
