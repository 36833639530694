<template>
  <div class="wrapper">
    <h1 class="login-header">仮登録完了</h1>
    <section class="text-wrapper">
      <p class="p-form p-form--single">
      仮登録メールをお送りいたしました。<br/>
      受信を確認いただき、メールに記載のURLより、本登録へとお進みください。
      </p>
      <p class="c-notice-message">
        ※メール受信制限をされている方は、「ki-group.jp」からの受信許可設定をしてください
      </p>
    </section>

  </div>
</template>

<script>

export default {
  name: 'MemberRegist',
  data() {
    return {
      isConfirmAutoLogin: false,
      isLoading: false,
    };
  },
  created() {
    if (this.$store.state.user) {
      this.$emit('update', 'form');
    }
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@charset "UTF-8";
@import "../../../assets/style/parts_responsive.scss";

.text-wrapper {
  padding: 2px 22px;
}

.p-button input[type=button]{
  margin:0;
}

.c-autologin{
  font-size:14px;
  padding:12px;
  text-align:center;
}

.c-agreement{
  font-size:10px;
  padding: 12px;
  text-align: center;
}

.c-forgetpassword {
  padding: 12px;
  text-align:right;
}

.p-form--single {
  font-size: 14px;
  line-height: 32px;
}

.c-notice-message {
  font-size: 12px;
  margin-top: 26px;
  color: red;
  line-height: 22px;
}

@media(min-width: 640px) {
  .text-wrapper {
    min-height: 0px;;
  }

  .wrapper {
    text-align: center;
  }

  .text-wrapper {
    p {
      margin: 22px auto 0;
    }
  }

  .underline-header {
    text-align: left;
  }
}
</style>
