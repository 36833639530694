<template>
  <validation-provider :name="name" ref="prov" :vid="vid" :rules="rules" v-slot="{ errors }" :mode="mode">
    <floating-tooltip
      :expanded="expandedTooltip"
      @clicked="clearErrors"
      :show="shouldShowTooltip(errors)"
      :text="errors[0]"
      :arrowPosition="tooltipArrowPosition"
      :positionBottom="tooltipPositionBottom"
    />
    <slot v-bind:clear="clearErrors"/>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import FloatingTooltip from '@/components/common/FloatingTooltip';

export default {
  name: 'ValidationWrapper',
  props: {
    value: {
      default: '',
    },
    rules: String,
    vid: String,
    name: String,
    expandedTooltip: Boolean,
    tooltipArrowPosition: String,
    tooltipPositionBottom: Number,
    mode: String,
  },
  components: { ValidationProvider, FloatingTooltip },
  data() {
    return {
      isWaiting: false,
    };
  },
  methods: {
    clearErrors() {
      this.$refs['prov'].reset();
    },
    shouldShowTooltip(errors) {
      const result = (errors[0]);
      if (result && !this.isWaiting) {
        window.setTimeout(() => {
          this.clearErrors();
          this.isWaiting = false;
        }, 3000);
        this.isWaiting = true;
      }
      return !!result;
    },
  },
};
</script>
