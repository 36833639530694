import { render, staticRenderFns } from "./MyPageChangePassword.vue?vue&type=template&id=2881912f&scoped=true&"
import script from "./MyPageChangePassword.vue?vue&type=script&lang=js&"
export * from "./MyPageChangePassword.vue?vue&type=script&lang=js&"
import style0 from "./MyPageChangePassword.vue?vue&type=style&index=0&id=2881912f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2881912f",
  null
  
)

export default component.exports