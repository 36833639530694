<template>
  <header class="header-wrapper">
    <div class="l-header">
      <div class="l-header__minilogo">
        <img
          class="l-footer__logo"
          src="@/assets/logo-keiai.png"
          alt="ALT"
          />
      </div>
      <a href="/">
        <h1>
          <img class="l-header__logo" src="@/assets/resources/logo.png" alt="おトクな不動産情報ミツカル">
        </h1>
      </a>

      <div class="l-header__buttons">
        <div class="l-header__buttons-full">
          <a v-if="!isLoggedIn" class="c-button c-button--red u-mR16" href="/member/signup">会員登録</a>
          <a v-if="!isLoggedIn" class="c-button" @click="logIn">ログイン</a>
          <a v-else class="c-button" @click="logOut">ログアウト</a>
        </div>

        <div v-if="isMobile && !isLoggedIn" class="c-tooltip-link-wrapper">
          <span class="c-tooltip-link" @click="isMenuDisplay = !isMenuDisplay">
            <img src="@/assets/resources/menu.png" alt="">
          </span>
          <div v-if="isMenuDisplay" class="c-tooltip-wrapper">
            <div class="c-tooltip-overlay" @click="isMenuDisplay = false"></div>
            <div class="c-tooltip">
              <div v-if="!isLoggedIn" class="c-tooltip-item">
                <a class="c-tooltip-item-link" @click="logIn">ログイン</a>
              </div>
              <div v-else class="c-tooltip-item">
                <a class="c-tooltip-item-link" @click="logOut">ログアウト</a>
              </div>
              <div v-if="!isLoggedIn" class="c-tooltip-item">
                <a class="c-tooltip-item-link" href="/member/signup">会員登録</a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isMobile && isLoggedIn" class="c-tooltip-link-wrapper">
          <a class="c-button" @click="logOut">ログアウト</a>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import { Auth } from 'aws-amplify';
import isMobile from '@/plugins/isMobile';

export default {
  name: 'CommonHeader',
  mixins: [isMobile],
  data() {
    return {
      isMenuDisplay: false,
      fav: [],
      view: [],
      search: [],
    };
  },
  methods: {
    async logOut() {
      await Auth.signOut();
      this.$router.push('/member/signup')
    },
    logIn() {
      this.$router.push('/login');
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user;
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@charset "UTF-8";
$mobile-width: 712px;

.header-wrapper {
  border-bottom: 1px solid #ccc;
}

.l-header {
  max-width: 1100px;
  margin: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 61px;
  padding: 12px 40px;

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 192px;
  }
  &__minilogo {
    width: 192px;
    img {
      width: 74px;
      height: 40px;
    }
  }
  &__logo {
    padding: 0 20px;
    width: 145px;
  }
}

.c-button {
  padding: 10px 14px;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  color: #666;
  font-size: 12px;

  &--red {
    border: 0;
    color: #fff;
    background: #E83532;
  }
}

.c-actionBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 72px;
  padding: 6px 0;
  border: 1px solid #ccc;
  border-radius: 10px;

  &__icon {
    width: 24px;
    height: 23px;
    margin-bottom: 8px;
  }
  &__text, &__count {
    color: #666;
    font-size: 12px;
  }
  &__text {
    margin-bottom: 4px;
    width: 100%;
    text-align: center;
  }
}

.c-menu, .c-drawer {
  display: none;
}

.u-mR16 {
  margin-right: 16px;
}
.u-mR20 {
  margin-right: 20px;
}

.u-mR10 {
  margin-right: 10px;
}

@media screen and (max-width: $mobile-width) {
  .l-header {
    padding: 10px 15px;

    &__buttons-full {
      display: none;
    }
    &__logo {
      width: 128px;
    }
    &__buttons {
      width: 90px;
    }
    &__minilogo {
      width: 90px;
    }
  }

  .c-actionBox {
    width: 64px;
    // height: 56px;

    &--history {
      display: none;
    }
    &__icon {
      width: 16px;
      height: 15px;
    }
    &__text {
      font-size: 10px;
      margin: 1px 4px;
      width: 100%;
      text-align: center;
    }
  }

  .c-tooltip-link-wrapper {
    position: relative;
    .c-tooltip-link {
      display: block;
      margin-left: 10px;
      width: 28px;
      height: 28px;
    }

    .c-tooltip-wrapper {
      .c-tooltip-overlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 3;
      }

      .c-tooltip {
        position: absolute;
        width: 180px;
        height: 86px;
        z-index: 4;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 5px 8px 1px rgba(50, 50, 50, 0.36);
        right: 2px;
        top: 6px;
        display: flex;
        flex-direction: column;

        .c-tooltip-item:first-child {
          border-bottom: 1px solid #CCCCCC;
        }

        .c-tooltip-item {
          height: 42px;
          a {
            display: flex;
            align-items: center;
            height: 100%;
            padding-left: 18px;
          }
        }
      }

    }


  }

  .c-drawer {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    z-index: 210;

    &__inner {
      position: absolute;
      top: 0;
      right: 0;
      width: 75%;
      height: 100%;
      background: #fff;
    }
    &__title {
      padding: 13px 20px 17px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      background: #E83532;
    }
    &__item {
      border-bottom: 1px solid #ccc;
    }
    &__link {
      display: block;
      width: 100%;
      padding: 13px 20px;
      color: #666;
      font-size: 14px;

      &--flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &__count {
      padding: 7px 10px;
      border-radius: 20px;
      color: #fff;
      font-size: 14px;
      background: #666;
    }
  }
}

.p-x {
  width: 32px;
  height: 32px;
  position: absolute;
  left: -40px;
  top: 8px;
}

</style>
