import axios from 'axios';
import { ValidationProvider, extend, setInteractionMode } from 'vee-validate';
import { min, required, email, numeric, confirmed } from 'vee-validate/dist/rules';
import { retry, sleep } from '@/utils/index';

setInteractionMode('eager');

const zipRegex = /^[0-9]{3}-[0-9]{4}$/g;
const phoneRegex = /^(0([1-9]{1}-?[1-9]\d{3}|[1-9]{2}-?\d{3}|[1-9]{2}\d{1}-?\d{2}|[1-9]{2}\d{2}-?\d{1})-?\d{4}|0[789]0-?\d{4}-?\d{4}|050-?\d{4}-?\d{4})$/g;

extend('required', {
  ...required,
  message: '{_field_}を入力してください。'
});

let lastValue = '';
let lastAvailable = false;
extend("isUniquePropertyManagementNumber", {
  validate: async value => {
      // 初回の空文字は抜ける
      if (value === '') {
        return;
      }
      // 同じ入力値ならバリデーションが発火しても抜ける
      if (value === lastValue) {
        return lastAvailable;
      }

      lastValue = value; // apiを叩く回数を減らすために前回の入力値を保管（前回と今回で入力値が同じならreturn）
      await retry(async () => {
        const url = `${process.env.VUE_APP_USERS_API_ENDPOINT}/property-status/${value}`;
        const { data } = await axios.get(url, {
          headers: {
            "Content-Type": "application/json"
          },
          timeout: 4000
        })
        lastAvailable = data.data.available
      }, (e) => {
        console.error(e.response);
        lastAvailable = false
        if(e.response !== undefined) { return }
        return sleep(3000);
      });
      return lastAvailable;
    },
    message: "契約物件番号が誤っています。"
});
extend('numeric', {
  ...numeric,
  message: '数字を入力してください。'
});
extend('email', {
  ...email,
  message: '正しい形式で入力してください。メールアドレスは、「mitsukaru@example.com」のような形式で入力してください。'
});
extend('zip', {
  validate: value => value.match(zipRegex),
  message: '正しい形式で入力ください。'
});
extend('phone', {
  validate: value => value.match(phoneRegex),
  message: '正しい形式で入力ください。'
});
extend('passwordIsNew', {
  params: ['target'],
  validate: (value, { target }) => {
    if (value === target) {
      return false;
    }
    return true;
  },
  message:
    '新しいパスワードは、現在のパスワードとは別である必要があります。'
});
extend('lessThan', {
  params: ['target'],
  validate: (value, { target }) => {
    if (+value >= +target) {
      return false;
    }
    return true;
  },
  message:
    '正しい値を入力して下さい。',
});
extend('min', {
  ...min,
  message:
    '正しい値を入力して下さい。',
});
extend('confirmed', {
  ...confirmed,
  message:
    '新しく設定されたパスワードと一致したパスワードを入力してください。'
});

export default ValidationProvider;
