<template>
  <div class="complete-wrapper card-wrapper">
    <h1 class="p-standard-h1">会員登録</h1>
    <ol class="p-formchart">
      <li>
        <div class="step">1</div>
        <div class="label">入力</div>
      </li>
      <div class="spacer"></div>
      <li>
        <div class="step">2</div>
        <div class="label">確認</div>
      </li>
      <div class="spacer"></div>
      <li class="active">
        <div class="step">3</div>
        <div class="label">完了</div>
      </li>
    </ol>
    <p>会員登録が完了しました。</p>

    <form>
      <div>
        <input
          type="button"
          name="login"
          class="submit-button round-button"
          @click="login"
          value="ログイン"
        />
      </div>
    </form>

  </div>
</template>

<script>
export default {
  name: 'MemberRegistConfirm',
  data() {
    return {};
  },
  props: ['args'],
  methods: {
    login() {
      this.$router.push('/login');
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@charset "UTF-8";
@import "../../../assets/style/parts_responsive.scss";

.p-standard-h1 {
  text-align: center;
}

p {
  margin: 40px;
  text-align: center;
  font-size: 16px;
}

.complete-wrapper {
  height: 540px;
  padding: 12px;
}

@media(min-width: 640px) {
  .card-wrapper {
    height: 364px;
    width: 720px;
    margin: auto;
  }
}

</style>
