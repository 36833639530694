import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router';
import ValidationProvider from "./plugins/validation";
import { Amplify } from 'aws-amplify';

import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Vue.config.ignoredElements = [/amplify-\w*/];


applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.productionTip = false;

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env.VUE_APP_AWS_PROJECT_REGION,
        userPoolId: process.env.VUE_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_WEB_CLIENT_ID,
        mandatorySignIn: true,

            sameSite: "strict",
            secure: true
        },

        // OPTIONAL - customized storage object
        // storage: MyStorage,
});


new Vue({
  render: h => h(App),
  store,
  router,
  components: { App, ValidationProvider }
}).$mount('#app')
