<template>
  <validation-wrapper ref="prov" tag="div" :vid="vid" class="validation-wrapper" :rules="rules" >
    <div :class="doubleSort ? 'u-select-wrapper double-sort-arrow' : 'u-select-wrapper'">
      <select :value="value" @input="$emit('input', $event.target.value)" :disabled="loading || !items || !items[0]" @change="$emit('change', $event)" v-bind="$attrs">
        <option v-if="loading" value="" disabled selected>Loading...</option>
        <option v-if="!loading && placeholder" value="" disabled selected>{{placeholder}}</option>
        <option v-for="(item, index) in items" :key="index" :value="item[valueKey]">{{item[nameKey]}}</option>
      </select>
    </div>
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/components/common/validation/ValidationWrapper';

export default {
  name: 'ValidatedSelect',
  props: ['value', 'rules', 'vid', 'expandedTooltip', 'items', 'placeholder', 'valueKey', 'nameKey', 'doubleSort', 'loading'],
  components: { ValidationWrapper },
};
</script>

<style scoped lang="scss">
input {
  width: 100%;
  margin-top: 0px;
  height: 34px;
}

.validation-wrapper {
  width: 100%;
}

.fade-enter-active, .fade-leave-active {
  z-index: 10;
  transition: opacity .5s;
  pointer-events: none;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  pointer-events: none;
  z-index: 10;
  opacity: 0;
}
</style>
