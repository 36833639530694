<template>
  <transition name="modal-fade">
    <div class="modal-mask" v-if="modal">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div @click="close" class="modal-close-icon">
            <img class="button-icon" src="@/assets/icon-close-button-original.png" alt="">
          </div>

          <div class="modal-content-wrapper">

            <div class="modal-header">
              <h3>{{ header }}</h3>
            </div>

            <div class="modal-body">
              <slot>
              </slot>
            </div>

            <div v-if="isMobile" class="modal-controls">
              <slot name="controls">
                <button @click="close" class="modal-button">いいえ</button>
                <button @click="submit" class="modal-button">はい</button>
              </slot>
            </div>
            <div v-else class="modal-controls">
              <slot name="controls">
                <button @click="submit" class="modal-button">はい</button>
                <button @click="close" class="modal-button">いいえ</button>
              </slot>
            </div>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import isMobile from '@/plugins/isMobile';

export default {
  name: 'ConfirmationModal',
  props: ['modal', 'header'],
  mixins: [isMobile],
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    }
  },
};

</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
  backdrop-filter: blur(30px);
}

.modal-wrapper {
  padding-top: 200px;
  display: table-cell;
}

.modal-container {
  position: relative;
  width: 90%;
  height: 360px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-content-wrapper {
  padding: 55px 32px;
  position: relative;
}

.modal-close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  opacity: .6;
}

.modal-close-icon:hover {
  opacity: 0.5
}

.modal-header h3 {
  font-size: 20px;
  margin-top: 0;
  color: #666666;
  text-align: center;
  font-weight: bold;
}

.modal-body {
  margin: 42px 0;
  color: #666666;
  line-height: 28px;
  font-size: 14px;
}

.modal-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-button {
  border: 1px solid #CCCCCC;
  border-radius: 26px;
  height: 48px;
  width: 128px;
  color: #E83532;
  font-size: 16px;
  font-weight: bold;
}

.modal-button:first-child {
  margin-right: 14px;
}

.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.25s;
}
.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
}

@media (min-width: 640px) {

  .modal-content-wrapper {
    padding: 40px 30px 20px 30px ;
  }
  .modal-container {
    height: 294px;
    width: 640px; 
  }

  .modal-body {
    text-align: center;
    margin-bottom: 60px;
  }
}

</style>
