import axios from 'axios';
import ApiUrls from '../consts/api-urls';

export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    // APIs
    getPrefectureList(n = 0, kind = 0) {
      return axios.get(ApiUrls.getPrefectureListUrl(n, kind)).then(r => r.data);
    },
    getCityList(n = 0, cd, kind) {
      return axios.get(ApiUrls.getCityListUrl(n, cd, kind)).then(r => r.data);
    },
    getMypageInfoList(limit = 0) {
      return axios.get(
        ApiUrls.getMypageInfoListUrl(limit),
        {
          headers: { 'X-API-KEY': process.env.VUE_APP_MICROCMS_API_KEY_1 },
        })
        .then(m => m);
    },
  },
};
