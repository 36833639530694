import axios from 'axios';

const usersEndpoint = process.env.VUE_APP_USERS_API_ENDPOINT;

const registerUser = async body => {
  try {
    return await axios.post(`${usersEndpoint}/users/register`, body);
  } catch (e) {
    throw e.response.data;
  }
};

const completeRegistration = async body => {
  try {
    return await axios.put(`${usersEndpoint}/users/confirm`, body);
  } catch (e) {
    throw e.response.data;
  }
};

const updateAttributes = async (username, body) => {
  try {
    const result = await axios.put(`${usersEndpoint}/users/${username}`, body);
    return result;
  } catch (e) {
    throw e.response.data;
  }
};

const changePassword = async body => {
  try {
    return await axios.put(`${usersEndpoint}/change-password`, body);
  } catch (e) {
    throw e.response.data;
  }
};

const initiateForgotPassword = async body => {
  try {
    return await axios.put(
      `${usersEndpoint}/users/initiate-reset-password`,
      body
    );
  } catch (e) {
    throw e.response.data;
  }
};

const completeNewPassword = async body => {
  try {
    return await axios.put(`${usersEndpoint}/users/confirm-new-password`, body);
  } catch (e) {
    throw e.response.data;
  }
};

// const getUserEnabledServices = async (email, code) => {
//   try {
//     const result = await axios.get(
//       `${usersEndpoint}/enabled-services?email=${email}&code=${code}`
//     );
//     return result.data.data;
//   } catch (e) {
//     throw e.response.data;
//   }
// };

const confirmOwner = async body => {
  try {
    return await axios.post(`${usersEndpoint}/users/confirm-owner`, body);
  } catch (e) {
    throw e.response.data;
  }
};
export {
  registerUser,
  completeRegistration,
  changePassword,
  initiateForgotPassword,
  completeNewPassword,
  // getUserEnabledServices,
  confirmOwner,
  updateAttributes
};
