import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    prop: {},
    favorite: [],
    view: [],
    search: [],
  },
  getters: {
    getFavorite(state) {
      return state.favorite;
    },
    getViewHistory(state) {
      return state.view;
    },
    getSearchHistory(state) {
      return state.search;
    },
    getProp(state) {
      return state.prop;
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    // ユーザー情報保存
    setUser(state, user) {
      state.user = user;
    },
    setFavorite(state, favorite) {
      state.favorite = favorite;
      localStorage.setItem('fav', JSON.stringify(favorite));
    },
    setViewHistory(state, view) {
      state.view = view;
      localStorage.setItem('view', JSON.stringify(view));
    },
    setSearchHistory(state, search) {
      state.search = search;
      localStorage.setItem('search', JSON.stringify(search));
    },
    setProp(state, prop) {
      state.prop = prop;
    },
  },
  actions: {
  },
  modules: {
  },
});

