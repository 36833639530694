<template>
  <div class="card-wrapper wrapper">
    <div class="inner-wrapper">
      <h1 class="p-standard-h1">会員登録</h1>
      <ol class="p-formchart">
        <li>
          <div class="step">1</div>
          <div class="label">入力</div>
        </li>
        <div class="spacer"></div>
        <li class="active">
          <div class="step">2</div>
          <div class="label">確認</div>
        </li>
        <div class="spacer"></div>
        <li>
          <div class="step">3</div>
          <div class="label">完了</div>
        </li>
      </ol>
      <form>
        <div class="result-wrapper">
          <div v-if="userJoiningOwnersClub" class="confirm-label-wrapper">
            <p>契約物件番号</p>
          </div>
          <div class="confirm-value-wrapper name-wrapper">
            <span>{{ args.ownersClubId }}</span>
          </div>
        </div>
        <div class="result-wrapper">
          <div class="confirm-label-wrapper">
            <p>名前 / フリガナ</p>
          </div>
          <div class="confirm-value-wrapper name-wrapper">
            <span>{{ args.sei }}</span>
            <span>{{ args.mei }}</span>
            <span>/</span>
            <span>{{ args.seikana }}</span>
            <span>{{ args.meikana }}</span>
          </div>
        </div>
        <div class="result-wrapper">
          <div class="confirm-label-wrapper">
            <p>住所</p>
          </div>
          <div class="confirm-value-wrapper address-wrapper">
            <span class="prefecture">{{ args.prefecture }}</span>
            <span>{{args.address}}</span>
            <span class="building">{{args.addressLocal}}</span>
          </div>
        </div>
        <div class="result-wrapper">
          <div class="confirm-label-wrapper">
            <p>電話番号（携帯）</p>
          </div>
          <div class="confirm-value-wrapper">
            <span class="telephone">{{ args.tel }}</span>
          </div>
        </div>
        <div class="result-wrapper" v-if="args.home_tel">
          <div class="confirm-label-wrapper">
            <p>電話番号（自宅）</p>
          </div>
          <div class="confirm-value-wrapper">
            <span class="telephone">{{ args.home_tel }}</span>
          </div>
        </div>
        <div class="result-wrapper">
          <div class="confirm-label-wrapper">
            <p>メールアドレス</p>
          </div>
          <div class="confirm-value-wrapper">
            <span>{{args.email}}</span>
          </div>
        </div>
        <div v-if="!userJoiningOwners">
          <div class="result-wrapper">
            <div class="confirm-label-wrapper">
              <p>希望の物件種別</p>
            </div>
            <div class="confirm-value-wrapper">
              <span v-for="item in args.desiredKind" :key="item" style="margin-right: 14px">{{item}}</span>
            </div>
          </div>
          <div class="result-wrapper">
            <div class="confirm-label-wrapper">
              <p>希望エリア</p>
            </div>
            <div class="confirm-value-wrapper">
              <span style="margin-right: 14px">{{args.desiredAreaPref}}</span>
              <span>{{args.desiredAreaCity}}</span>
            </div>
          </div>
          <div class="result-wrapper">
            <div class="confirm-label-wrapper">
              <p>希望価格帯</p>
            </div>
            <div class="confirm-value-wrapper">
              <span>{{priceRangeLowerName}} ~ {{priceRangeUpperName}}</span>
            </div>
          </div>
          <div class="result-wrapper">
            <div class="confirm-label-wrapper">
              <p>月々の支払想定額</p>
            </div>
            <div class="confirm-value-wrapper">
              <span>{{args.desiredMonthlyPrice}}万円</span>
            </div>
          </div>
          <div class="result-wrapper">
            <div class="confirm-label-wrapper">
              <p>購入動機</p>
            </div>
            <div class="confirm-value-wrapper">
              <span v-for="item in args.motiveForSearch.filter(a => a !== 'その他')" :key="item" style="margin-right: 14px">{{ item }}</span>
              <span v-if="args.motiveForSearch.includes('その他')">その他：{{args.motiveForSearchAdditional}}</span>
            </div>
          </div>
        </div>
        <!-- <div class="result-wrapper">
          <div class="confirm-label-wrapper">
            <p>世帯年収</p>
          </div>
          <div class="confirm-value-wrapper">
            <span>{{getIncome(args.income)}}</span>
          </div>
        </div>
        <div class="result-wrapper">
          <div class="confirm-label-wrapper">
            <p>ご希望の毎月の支払額 </p>
          </div>
          <div class="confirm-value-wrapper">
            <span v-show="args.desiredRent">{{args.desiredRent}}万円</span>
          </div>
        </div>
        <div class="result-wrapper">
          <div class="confirm-label-wrapper">
            <p>物件をお探しのエリア</p>
          </div>
          <div class="confirm-value-wrapper">
            <span>{{args.area}}</span>
          </div>
        </div> -->
        <p v-if="err.isErr" class="p-errmsg">
          <span>
            <img src="@/assets/attention.png" alt="エラー" width="22" height="22" />
          </span>
          {{ err.msg }}
        </p>
        <div class="buttons-wrapper">
          <div class="revise-button-wrapper">
            <input
              type="button"
              class="revise-button round-button"
              name="login"
              value="修正する"
              @click="returnToForm"
            />
          </div>
          <div>
            <input
              v-if="submitting"
              type="button"
              disabled
              class="submit-button round-button disabled-button"
              name="login"
              value="登録中です・・・"
            />
            <input
              v-else
              type="button"
              name="login"
              class="submit-button round-button"
              @click="submit"
              value="登録を完了する"
            />
            <div class="c-goto-top-button-wrapper">
              <input
                v-if="isMobile"
                type="button"
                name="ユーザ情報変更"
                value="ページTOPへ"
                class="revise-button round-button"
                @click="scrollToTop"
                />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { completeSignUpFlow } from '@/utils/auth';
import IsMobile from '@/plugins/isMobile';

const priceRangesLower = require('@/utils/price-data/desiredPriceRangesLower');
const priceRangesUpper = require('@/utils/price-data/desiredPriceRangesUpper');

const ONE_MAN_EN = 10000;

export default {
  name: 'MemberRegistConfirm',
  data() {
    return {
      email: '',
      username: '',
      code: '',
      err: {
        isErr: false,
        msg: '',
      },
      submitting: false,
    };
  },
  mixins: [IsMobile],
  props: ['args', 'userJoiningOwners', 'userJoiningOwnersClub'],
  computed: {
    priceRangeLowerName() {
      const item = priceRangesLower.find(a => a.value === +this.args.desiredPriceRangeLower);
      return item.name;
    },
    priceRangeUpperName() {
      const item = priceRangesUpper.find(a => a.value === +this.args.desiredPriceRangeUpper);
      return item.name;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    navigateConfirm() {
      this.$emit('updated', 'complete');
    },
    getIncome(value) {
      const income = this.incomes.find((i) => i.value === value);
      if (!income) {
        return "";
      }
      return income.name;
    },
    returnToForm() {
      this.$emit('updated', 'form');
    },
    buildRawPhoneNumber(phone) {
      let temp = phone[0] === "0" ? phone.replace("0", "") : phone;
      return "+81-" + temp;
    },
    buildPhoneNumber(phone) {
      let temp = phone[0] === "0" ? phone.replace("0", "") : phone;
      const split = temp.split("-");
      const joined = split.join("");
      return "+81" + joined;
    },
    async submit() {
      if (this.submitting) {
        return;
      }
      try {
        this.submitting = true;
        this.err.isErr = false;
        this.err.msg = '';

        const attributes = {
          family_name: this.args.sei,
          given_name: this.args.mei,
          phonetic_family_name: this.args.seikana,
          phonetic_given_name: this.args.meikana,
          postal: this.args.zip,
          address: this.args.address,
          phone_number: this.buildPhoneNumber(this.args.tel),
          home_phone: this.args.home_tel ? this.buildPhoneNumber(this.args.home_tel) : null,
          building: this.args.addressLocal,
          prefecture: this.args.prefecture,
          raw_phone_number: this.buildRawPhoneNumber(this.args.tel),
          raw_home_phone_number: this.args.home_tel ? this.buildRawPhoneNumber(this.args.home_tel) : null,
          desired_monthly_price: this.args.desiredMonthlyPrice ? +this.args.desiredMonthlyPrice * ONE_MAN_EN : '',
          motive_for_search: this.args.motiveForSearch.join(","),
          motive_for_search_additional: this.args.motiveForSearchAdditional,
          desired_price_range_upper: +this.args.desiredPriceRangeUpper,
          desired_price_range_lower: +this.args.desiredPriceRangeLower,
          desired_kind: this.args.desiredKind.join(","),
          desired_area_pref: this.args.desiredAreaPref,
          desired_area_city: this.args.desiredAreaCity,
        };

        if (this.userJoiningOwnersClub) {
          attributes.owners_club_id = this.args.ownersClubId;
        }

        await completeSignUpFlow(
          this.username,
          this.code,
          this.args.password,
          attributes,
        );
        this.navigateConfirm();
      } catch (e) {
        console.log(e);
        if (e.code === "code_invalid") {
          this.err.isErr = true;
          this.err.msg = "メールの有効期限が切れました。"
        } else if (e.code === "phone_number_in_use") {
          this.err.isErr = true;
          this.err.msg = '電話番号 (携帯) はすでに登録されています。';
        } else {
          this.err.isErr = true;
          this.err.msg = "サーバーエラーが発生しました。もう一度最初からやり直して下さい。";
        }
      } finally {
        this.submitting = false;
      }
    },
  },
  created() {
    this.email = this.$route.query.email;
    this.username = this.$route.query.username;
    this.code = this.$route.query.code;
  }
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@charset "UTF-8";
@import "../../../assets/style/parts_responsive.scss";

.p-standard-h1 {
  text-align: center;
}
.p-form {
  input {
    height: 32px;
  }
  p {
      &.required {
        margin-left: 1em;
        padding: 0px 6px 0px 6px;
        font-size: 10px;
        color: white;
        background-color: #c44;
      }
      &.optional {
        margin-left: 1em;
        padding: 0px 6px 0px 6px;
        font-size: 10px;
        color: white;
        background-color: #aaaaaa;
    }
  }
}

form {
  margin-top: 20px;
  padding: 12px;
  padding-bottom: 40px;
}

.result-wrapper {
  margin-top: 12px;
  .confirm-label-wrapper {
    background-color: #EFEFEF;
    font-size: 14px;
    padding: 12px;
    display: flex;
    align-items: center;
  }
  .confirm-value-wrapper {
    min-height: 38px;
    font-size: 0;
    padding: 10px 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      margin: 0;
      font-size: 14px;
    }

    &.address-wrapper {
      .address
      {
        margin-left: 8px;
      }
    }

    &.name-wrapper {
      .mei {
        margin-left: 12px;;
      }

      .separator {
        padding: 0 4px;
      }

      .meikana {
        margin-left: 12px;;
      }
    }
  }
}

.c-goto-top-button-wrapper {
  width: 222px;
  margin: 30px auto;

  input {
    height: 41px;
    font-size: 14px;
  }
}

@media(min-width: 716px) {
  .inner-wrapper {
    width: 680px;
    margin: auto;
  }
  .result-wrapper {
    display: flex;
    margin: 1px 0;

    .confirm-label-wrapper {
      width: 200px;
    }
    .confirm-value-wrapper {
      width: 340px;
    }
  }

  .buttons-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .revise-button {
    cursor: pointer;
    width: 152px;
    margin-right: 40px;
  }
}

@media(min-width: 1000px) {
  .result-wrapper {
    width: 680px;

  }
}

</style>
