<template>
  <section id="my-page-header">
    <div id="my-page-navigation-wrapper">
      <button
        @click="updateHeading('top')"
        :class="{'js-nav__active': this.heading === 'top'}"
        class="p-mypageHeding--nav__top js-top"
      >
        <img class="button-icon" src="@/assets/home-red.png" alt="">
        <p>マイページ</p>
      </button>
      <button
        @click="updateHeading('mod')"
        :class="{'js-nav__active': this.heading === 'mod' || this.heading === 'profile-complete'}"
        class="p-mypageHeding--nav__mod js-mod"
      >
        <img class="button-icon" src="@/assets/edit-red.png" alt="">
        <p>ご登録内容の変更</p>
      </button>
      <button
        @click="updateHeading('pass')"
        :class="{'js-nav__active': this.heading === 'pass' || this.heading === 'password-complete'}"
        class="p-mypageHeding--nav__pass js-pass"
      >
        <img class="button-icon" src="@/assets/icon-change-password.png" alt="">
        <p>パスワードの変更 </p>
      </button>
    </div>

  </section>
</template>

<script>
export default {
  name: 'MypageHeading',
  props: ['heading'],
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
    };
  },
  methods: {
    updateHeading(val) {
      if (val !== this.heading) {
        this.$emit('updated', val);
      }
    },
  },
};
</script>

<style scoped lang='scss'>
#my-page-header {

  #my-page-navigation-wrapper {
    display: flex;

    button {
      height: 61px;
      width: 65px;
      border: 1px solid #EFEFEF;
      
      .button-icon {
        height: 28px;
        width: 28x;
        margin-right: 6px;
      }
    }
    p {
      font-size: 12px;
      line-height: 20px;
    }
    .js-top {
      width: 52px;
      .button-icon {
        margin-right: 0px;
      }
    }
    .js-top,
    .js-mod,
    .js-pass {
      flex: 1;
    }
  }


  .js-nav__active {
    background-color: #FDE9EA;
  }
}

@media (min-width: 640px) {
  #my-page-header {

    #my-page-image-wrapper {
      padding-left: 0px;
      margin: auto;
      flex-direction: column;
      width: 30%;

      #my-page-icon {
        height: 84px;
        width: 84px;
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }

    #my-page-navigation-wrapper {
      width: 396px; 
      margin: auto;

      .js-top {
        width: 52px;
        flex: 1;
      }
      button {
        font-size: 12px;
        height: 56px;
        border: none;
        text-align: center;

        .button-icon {
          margin-right: 6px;
        }
      }
      .js-top {
        p {
          display: block;
        }
        .button-icon {
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
