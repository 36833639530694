<template>
  <div id="password-change-wrapper">
    {{$route.params.userid}}
    <h2 class="my-page-header">パスワードの変更</h2>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form id="password-change-form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="password-change-field">
          <label class="p-form-title" for="current-password">現在のパスワード</label>
          <div class="input-wrapper">
            <ValidatedInput type="password" vid="current-password" name="パスワード" v-model="form.currentPassword" id="current-password" rules="required" password-icon/>
          </div>
        </div>
        <div class="password-change-field">
          <label class="p-form-title" for="change-password">新しいパスワード</label>
          <div class="input-wrapper">
            <ValidatedInput placeholder="半角英数字8文字以上（大文字・記号含む）" vid="change-password" type="password" name="パスワード" v-model="form.password" id="change-password" rules="required|passwordIsNew:@current-password" password-icon/>
          </div>
        </div>
        <div class="password-change-field">
          <label class="p-form-title" for="password-confirm">新しいパスワード(確認用)</label>
          <div class="input-wrapper">
            <ValidatedInput type="password" name="パスワード" v-model="form.passwordConfirm" id="password-confirm" rules="required|confirmed:change-password" password-icon/>
          </div>
        </div>
        <p v-if="err.isErr" class="p-errmsg">
          {{ err.msg }}
        </p>
        <input
          v-if="submitting"
          disabled
          type="submit"
          name="パスワード変更"
          value="変更中です・・・"
          class="submit-button disabled-button round-button"
          />
        <input
          v-else
          type="submit"
          name="パスワード変更"
          value="変更する"
          class="submit-button round-button"
          />
      </form>
    </ValidationObserver>
    <ConfirmationModal @close="modal = false" @submit="finish" :modal="modal" header="パスワード変更">
      パスワードを変更してもよろしいでしょうか？
    </ConfirmationModal>
  </div>

</template>

<script>
import isMobile from '@/plugins/isMobile';
import ValidatedInput from '@/components/common/validation/ValidatedInput';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import { ValidationObserver } from 'vee-validate';
import { updatePassword } from '@/utils/auth';

export default {
  name: 'MypageModifyProfileMobile',
  mixins: [isMobile],
  components: { ValidationObserver, ValidatedInput, ConfirmationModal},
  data() {
    return {
      err: {
        isErr: false,
        msg: '',
      },
      modal: false,
      submitting: false,
      form: {}
    };
  },
  methods: {
    onSubmit() {
      if (this.submitting) {
        return;
      }
      this.modal = true;
    },
    async finish() {
      if (this.submitting) {
        return;
      }
      this.modal = false;
      this.err.isErr = false;
      this.err.msg = '';
      try {
        this.submitting = true;
        await updatePassword(this.form.currentPassword, this.form.password);
        this.navigateComplete();
      } catch(e) {
        this.err.isErr = true;
        console.log(e);
        this.handleError(e);
      } finally {
        this.submitting = false;
      }
    },
    navigateComplete() {
        this.$emit('updated', 'password-complete');
    },
    navigateForgotPassword() {
      this.$router.push('/member/forgot-password');
    },
    handleError(e) {
      if ( e.code === 'InvalidParameterException' ) {
        this.err.msg = '正しい形式のパスワードを入力してください';
      } else if (e.code === 'NotAuthorizedException') {
        this.err.msg = '現在の正しいパスワードを入力してください。';
      } else {
        this.err.msg = 'エラーが発生しました。'
      }
    }
  },
};
</script>
<style scoped lang="scss">
@charset "UTF-8";
@import "../../assets/style/parts_responsive.scss";

.c-autologin {
  font-size: 14px;
  text-align: center;
}

.p-formchart {
  li:nth-child(1) {
    background: #f79;
    color: white;
    border: 0;
  }
}

#password-change-form {
  margin-top: 12px; 

  .password-change-field {
    padding: 12px 0;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    ::v-deep input {
      height: 34px;
    }
    label {
      width: 100%;
      height: 24px;
      font-size: 16px;
      span {
        &.required {
          margin-left: 1em;
          padding: 0px 6px 0px 6px;
          font-size: 10px;
          color: white;
          background-color: #c44;
        }
        &.optional {
          margin-left: 1em;
          padding: 0px 6px 0px 6px;
          font-size: 10px;
          color: white;
          background-color: #aaaaaa;
        }
      }
    }
  }
}

.c-forgetpassword-field {
  padding: 0 !important;

  .c-forgetpassword {
    cursor: pointer;
    font-size: 12px;
    color: #E83532;
    text-align: left;
  }
}

.input-wrapper {
  flex-wrap: wrap;
  width: 100%;
}

@media (min-width: 640px) {
  h2 {
    font-size: 18px;
  }


  #password-change-wrapper {
    margin: auto;
    max-width: 760px;
    background-color: white;
  }

  .input-wrapper {
    width: 54%;
    input {
      width: 100%;
    }
  }

  .c-forgetpassword-field {
    padding: 12px 0;
  }

  #password-change-form {
    .password-change-field {
      align-items: center;

      label {
        padding-right: 8px;
        height: 100%;
        width: 41%;
        text-align: right;
      }
    }
  }
}


</style>
