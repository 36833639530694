<template>
  <div class="flex">
    <input v-model="firstSetValue" @input="emitValue" v-bind="$attrs" @focus="$emit('focus')" placeholder="郵便番号" autocomplete="none"/>
    <span>-</span>
    <input v-model="secondSetValue" @input="emitValue" v-bind="$attrs" @focus="$emit('focus')" @blur="$emit('blur')" autocomplete="none"/>
  </div>
</template>

<script>
export default {
  name: 'ZipFieldInput',
  props: ['value'],
  components: {},
  data() {
    return {
      firstSetValue: '',
      secondSetValue: '',
    };
  },
  watch: {
    firstSetValue(e) {
      this.emitValue(e);
    },
    secondSetValue(e) {
      this.emitValue(e);
    },
    value: {
      immediate: true,
      handler(newValue) {
        this.fullNumber = newValue;
      },
    },
  },
  computed: {
    fullNumber: {
      get() {
        const combinedValue = `${this.firstSetValue}-${this.secondSetValue}`;
        return combinedValue.length > 1 ? combinedValue : '';
      },
      // eslint-disable-next-line consistent-return
      set(newValue) {
        if (!newValue) {
          return '';
        }
        const split = newValue.split('-');
        this.firstSetValue = split[0];
        this.secondSetValue = split[1];
      },
    },
  },
  methods: {
    emitValue() {
      this.$emit('input', this.fullNumber);
    },
  },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 262px;

  span {
    margin: 0 8px;
  }

  input {
    min-width: 0;
  }
}

</style>
