<template>
  <validation-wrapper name="郵便番号" ref="prov" :vid="vid" class="validation-wrapper" :rules="rules">
    <template v-slot:default="slotProps">
      <ZipFieldInput v-model="zip" v-bind="$attrs" @focus="slotProps.clear" @blur="$emit('blur')"/>
    </template>
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/components/common/validation/ValidationWrapper';
import ZipFieldInput from '@/components/common/validation/zip/ZipFieldInput';

export default {
  name: 'ValidatedZipField',
  props: ['value', 'rules', 'vid', 'expandedTooltip'],
  components: { ZipFieldInput, ValidationWrapper },
  data() {
    return {
      zip: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.zip = newValue;
      },
    },
    zip(value) {
      this.$emit('input', value);
    },
  },
};
</script>
