import axios from 'axios';
import { cacheMyUserId, getApiTokenSilently } from '@/utils/auth';
import { Auth } from "aws-amplify";

const endpoint = process.env.VUE_APP_MYPAGE_API_ENDPOINT;

const getAnonymousUserId = async () => {
  const response = await axios.get(`${endpoint}/anonymous-users/new`);
  const data = response.data;
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.data.myKiUserId;
};

const confirmAnonymousId = async id => {
  const response = await axios.put(`${endpoint}/anonymous-users/${id}`);
  const data = response.data;
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data;
};

const getAccessKey = async () => {
  const token = await getApiTokenSilently();
  const config = {
    headers: {
      Authorization: token
    }
  };
  const response = await axios.get(`${endpoint}/access-key`, config);
  const data = response.data;
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.data.key;
};

const getUserAttributes = async () => {
  const session = await Auth.currentSession();
  const user = await Auth.currentAuthenticatedUser();
  const config = {
    headers: {
      Authorization: session.accessToken.jwtToken
    }
  };
  const body = {
    email: user.attributes.email
  };
  const response = await axios.post(`${endpoint}/authentication`, body, config);
  const data = response.data;
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  return data.data;
};

const getMe = async id => {
  const token = await getApiTokenSilently();
  // we cache this id in localstorage to avoid unnecessary calls, and fall back to api when needed (cleared on logout)
  // const cachedId = getMyUserId();
  // if (cachedId) {
  //   return cachedId;
  // }

  const config = {
    headers: {
      Authorization: token,
      "X-USER": id
    }
  };
  const response = await axios.get(`${endpoint}/me`, config);
  const data = response.data;
  if (data.result !== "success") {
    throw new Error(data.error);
  }
  const idResult = data.data.myKiUserId;
  cacheMyUserId(idResult);
  return idResult;
};

export {
  getAnonymousUserId,
  confirmAnonymousId,
  getMe,
  getAccessKey,
  getUserAttributes
};
