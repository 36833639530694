<template>
  <div class="wrapper card-wrapper">
    <h1 class="p-standard-h1">会員登録</h1>
    <ol class="p-formchart">
      <li class="active">
        <div class="step">1</div>
        <div class="label">入力</div>
      </li>
      <div class="spacer"></div>
      <li>
        <div class="step">2</div>
        <div class="label">確認</div>
      </li>
      <div class="spacer"></div>
      <li>
        <div class="step">3</div>
        <div class="label">完了</div>
      </li>
    </ol>
    <p v-if="err.isErr" class="p-errmsg">
      <span>
        <img src="@/assets/attention.png" alt="エラー" width="22" height="22" />
      </span>
      {{ err.msg }}
    </p>
    <ValidationObserver ref="observer">
      <form id="profile-change-form" @submit.prevent="onSubmit">
        <div v-if="userJoiningOwnersClub" class="profile-change-field">
          <label class="p-form-title" for="name">契約物件番号<span class="required">必須</span></label>
          <div class="input-wrapper">
            <ValidatedInput
              v-model="form.ownersClubId" vid="owners-club-id" placeholder="KIS-000000000-000" type="text" name="契約物件番号" id="owners-club-id" rules="required|isUniquePropertyManagementNumber" class="inner-input-wrapper" mode="passive"
            />
          </div>
        </div>
        <div class="profile-change-field">
          <label class="p-form-title" for="name">パスワード<span class="required">必須</span></label>
          <div class="input-wrapper">
            <ValidatedInput
              v-model="form.password" vid="password" placeholder="半角英数字8文字以上（大文字・記号含む）" type="password" name="パスワード" id="password" rules="required|min:8" class="inner-input-wrapper" autocomplete="password"
            />
          </div>
        </div>
        <div class="profile-change-field">
          <label class="p-form-title" for="name">パスワード（確認）<span class="required">必須</span></label>
          <div class="input-wrapper">
            <ValidatedInput
              v-model="form.passwordConfirm" :expandedTooltip="true" type="password" name="パスワード確認" id="password-confirm" rules="required|confirmed:password" class="inner-input-wrapper" autocomplete="password"
            />
          </div>
        </div>
        <div class="profile-change-field name-field align-start">
          <label class="p-form-title" for="name">お名前<span class="required">必須</span></label>
          <div class="input-wrapper name-input-wrapper">
            <ValidatedInput
              v-model="form.sei" placeholder="姓" type="text" name="姓" id="family-name" rules="required" class="inner-input-wrapper" autocomplete="family-name"
            />
            <ValidatedInput
              v-model="form.mei" placeholder="名" type="text" name="名" id="given-name" rules="required" class="inner-input-wrapper top-margin" autocomplete="given-name"
            />
          </div>
        </div>
        <div class="profile-change-field align-start">
          <label class="p-form-title" for="name">フリガナ<span class="required">必須</span></label>
          <div class="input-wrapper name-input-wrapper">
            <ValidatedInput
              v-model="form.seikana" placeholder="セイ" class="inner-input-wrapper" type="text" name="セイ" id="phonetic-family-name" rules="required"
            />
            <ValidatedInput
              v-model="form.meikana" placeholder="メイ" class="top-margin inner-input-wrapper" type="text" name="メイ" id="phonetic-given-name" rules="required"
            />
          </div>
        </div>
        <div class="profile-change-field align-start">
          <label class="p-form-title" for="addresslocal">住所<span class="required">必須</span></label>
          <div class="input-wrapper">

            <ValidatedZipField
              @input="form.zip = $event" type="text" name="郵便番号" rules="required|zip" :expandedTooltip="true" class="inner-input-wrapper"
            />

            <PrefCitySelect :prefectureValue="form.prefecture" :cityValue="form.address" @input-prefecture="form.prefecture = $event" @input-city="form.address = $event" :prefectureList="preflist" ref="prefCitySelect" />

            <ValidatedInput
              v-model="form.addressLocal" placeholder="町名・番地・建物名（末尾まで入力）" class="top-margin inner-input-wrapper-long" type="text" name="住所" rules="required"
            />
          </div>
        </div>
          <div class="profile-change-field">
            <label class="p-form-title" for="tel">電話番号（携帯）<span class="required">必須</span></label>
            <div class="input-wrapper">
              <ValidatedTelephoneField
                @input="form.tel = $event" :value="form.tel" type="tel" name="電話番号（携帯）" id="tel" :expandedTooltip="true" rules="required|phone" class="inner-input-wrapper"
              />
            </div>
          </div>
          <div class="profile-change-field">
            <label class="p-form-title" for="tel">電話番号（自宅）<span class="optional">任意</span></label>
            <div class="input-wrapper">
              <ValidatedTelephoneField
                @input="form.home_tel = $event" :value="form.home_tel" type="tel" name="電話番号（自宅）" id="home_tel" :expandedTooltip="true" rules="phone" class="inner-input-wrapper" :placeholder="true"
              />
            </div>
          </div>
        <div class="profile-change-field">
          <label class="p-form-title" for="email">メールアドレス<span class="required">必須</span></label>
          <div class="input-wrapper">
            <!-- <ValidatedInput
              v-model="form.email" placeholder="ki-group@xxxx.com" type="email" name="メールアドレス" id="email" :expandedTooltip="true" rules="required|email" class="inner-input-wrapper"
            /> -->
            {{form.email}}
          </div>
        </div>
        <div v-if="!userJoiningOwners">
          <div class="profile-change-field">
            <label class="p-form-title" for="desiredKind">希望の物件種別 <span class="c-label-subtitle">(複数回答可）</span><span class="required">必須</span></label>
            <validation-wrapper ref="prov" class="input-wrapper" name="希望の物件種別" rules="required" :expandedTooltip="false" :tooltipPositionBottom="48">
              <div class="c-check-wrapper">
                <input v-model="form.desiredKind" type="checkbox" value="新築戸建て" id="新築戸建て">
                <label for="新築戸建て" class="c-checkbox-label"><span class="check-icon"></span>新築戸建て</label>
              </div>
              <div class="c-check-wrapper">
                <input v-model="form.desiredKind" type="checkbox" value="中古戸建て" id="中古戸建て">
                <label for="中古戸建て" class="c-checkbox-label"><span class="check-icon"></span>中古戸建て</label>
              </div>
              <div class="c-check-wrapper">
                <input v-model="form.desiredKind" type="checkbox" value="新築マンション" id="新築マンション">
                <label for="新築マンション" class="c-checkbox-label"><span class="check-icon"></span>新築マンション</label>
              </div>
              <div class="c-check-wrapper">
                <input v-model="form.desiredKind" type="checkbox" value="中古マンション" id="中古マンション">
                <label for="中古マンション" class="c-checkbox-label"><span class="check-icon"></span>中古マンション</label>
              </div>
              <div class="c-check-wrapper">
                <input v-model="form.desiredKind" type="checkbox" value="土地" id="土地">
                <label for="土地" class="c-checkbox-label"><span class="check-icon"></span>土地</label>
              </div>
            </validation-wrapper>
          </div>
          <div class="profile-change-field" id="desired-area-field">
            <label class="p-form-title" for="income">希望エリア<span class="required">必須</span></label>
            <div class="input-wrapper">
              <PrefCitySelect :prefectureValue="form.desiredAreaPref" :cityValue="form.desiredAreaCity" @input-prefecture="form.desiredAreaPref = $event" @input-city="form.desiredAreaCity = $event" :prefectureList="preflist" />
            </div>
          </div>
          <div class="profile-change-field">
            <label class="p-form-title" for="income">希望価格帯<span class="required">必須</span></label>
            <div class="input-wrapper">
              <div class="c-price-select-inner-input-wrapper">
                <ValidatedSelect class="select-wrapper inner-input-wrapper" :items="priceRangesLower" :doubleSort="true" rules="required|lessThan:@upper" name="ご希望の価格帯" v-model="form.desiredPriceRangeLower"  valueKey="value" nameKey="name" />
              </div>
              <span class="c-price-separator"> ~ </span>
              <div class="c-price-select-inner-input-wrapper">
                <ValidatedSelect class="select-wrapper inner-input-wrapper" :items="priceRangesUpper" :doubleSort="true" rules="required" name="ご希望の価格帯" vid="upper" v-model="form.desiredPriceRangeUpper"  valueKey="value" nameKey="name" />
              </div>
            </div>
          </div>
          <div class="profile-change-field">
            <label class="p-form-title" for="desired-monthly-price">月々の支払想定額<span class="optional">任意</span></label>
            <div class="inner-input-wrapper standard-field-input-wrapper">
              <div class="inner-input-wrapper c-input-finance-field-width">
                <ValidatedInput
                v-model="form.desiredMonthlyPrice" placeholder="10" type="text" name="desired-monthly-price" id="desired-monthly-price" rules="numeric" tooltipArrowPosition="left"
                />
                <span class="input-suffix">万円</span>
              </div>
            </div>
          </div>
          <div class="profile-change-field">
            <label class="p-form-title" for="motiveForSearch">購入動機 <span class="c-label-subtitle-motive">(複数回答可）</span><span class="required">必須</span></label>
            <validation-wrapper ref="prov" class="input-wrapper" name="購入動機" rules="required" :expandedTooltip="false" :tooltipPositionBottom="48">
              <div class="c-check-wrapper">
                <input v-model="form.motiveForSearch" type="checkbox" value="ご結婚" id="ご結婚">
                <label for="ご結婚" class="c-checkbox-label"><span class="check-icon"></span>ご結婚</label>
              </div>
              <div class="c-check-wrapper">
                <input v-model="form.motiveForSearch" type="checkbox" value="ご出産" id="ご出産">
                <label for="ご出産" class="c-checkbox-label"><span class="check-icon"></span>ご出産</label>
              </div>
              <div class="c-check-wrapper">
                <input v-model="form.motiveForSearch" type="checkbox" value="お子様のご進学" id="お子様のご進学">
                <label for="お子様のご進学" class="c-checkbox-label"><span class="check-icon"></span>お子様のご進学</label>
              </div>
              <div class="c-check-wrapper">
                <input v-model="form.motiveForSearch" type="checkbox" value="手狭になった" id="手狭になった">
                <label for="手狭になった" class="c-checkbox-label"><span class="check-icon"></span>手狭になった</label>
              </div>
              <div class="c-check-wrapper">
                <input v-model="form.motiveForSearch" type="checkbox" value="その他" id="その他">
                <label for="その他" class="c-checkbox-label"><span class="check-icon"></span>その他（下記にご入力ください）</label>
              </div>
              <div class="inner-input-wrapper">
                <ValidatedInput
                  v-model="form.motiveForSearchAdditional"
                  :disabled="!form.motiveForSearch.includes('その他')"
                  placeholder=""
                  type="text"
                  name="きっかけ"
                  id="current-rent"
                  :rules="form.motiveForSearch.includes('その他') ? 'required' : ''"
                />
              </div>
            </validation-wrapper>
          </div>

        </div>
        <common-agree-check v-model="agreeChecked" @clear="agreeTooltip = false" :error="agreeTooltip" :disabled="submitting" class="c-agree-check-wrapper">
          個人情報保護方針と会員規約に同意のうえ確認画面にお進みください。
        </common-agree-check>
        <div class="c-policy-wrapper">
          <a href="https://ki-group.co.jp/policy" target="_blank" class="c-privacy-policy">
            <img id="my-page-icon" src="@/assets/icon-document.png" alt="">
            <p>個人情報保護方針（プライバシーポリシー）</p>
          </a>
          <a href="https://ki-group.co.jp/member/agreement/" target="_blank" class="c-contract">
            <img id="my-page-icon" src="@/assets/icon-document.png" alt="">
            <p>会員規約（利用規約）</p>
          </a>
        </div>
        <p v-if="err.isErr" class="p-errmsg">
          <span>
            <img src="@/assets/attention.png" alt="エラー" width="22" height="22" />
          </span>
          {{ err.msg }}
        </p>
        <input
          v-if="!agreeChecked"
          @click="showAgreeTooltip"
          type="button"
          class="submit-button disabled-button round-button"
          value="規約に同意して確認画面へ進む"
        />
        <input
          v-else
          type="submit"
          name="ユーザ情報変更"
          value="規約に同意して確認画面へ進む"
          class="submit-button round-button"
          @click="scrollToTop"
          />
        <div class="c-goto-top-button-wrapper">
          <input
            v-if="isMobile"
            type="button"
            name="ユーザ情報変更"
            value="ページTOPへ"
            class="revise-button round-button"
            @click="scrollToTop"
            />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { Core as YubinBangoCore } from 'yubinbango-core'
import IsMobile from '@/plugins/isMobile';
import { ValidationObserver } from 'vee-validate';
import ValidatedInput from '@/components/common/validation/ValidatedInput';
import PrefCitySelect from '@/components/common/PrefCitySelect';
import ValidatedTelephoneField from '@/components/common/validation/telephone/ValidatedTelephoneField';
import ValidatedZipField from '@/components/common/validation/zip/ValidatedZipField';
import ValidationWrapper from '@/components/common/validation/ValidationWrapper';
import ValidatedSelect from '@/components/common/validation/ValidatedSelect';
import MixinAPI from '@/plugins/mixin-api';
import CommonAgreeMixin from "@/plugins/component-mixins/agree-check.js";

const priceRangesLower = require('@/utils/price-data/desiredPriceRangesLower');
const priceRangesUpper = require('@/utils/price-data/desiredPriceRangesUpper');

export default {
  name: 'MemberRegistForm',
  props: ['preflist', 'limitedPrefList', 'userJoiningOwners', 'userJoiningOwnersClub'],
  mixins: [MixinAPI, IsMobile, CommonAgreeMixin],
  components: { ValidationObserver, ValidatedInput, ValidatedTelephoneField, ValidatedZipField, PrefCitySelect, ValidationWrapper, ValidatedSelect },
  data() {
    return {
      err: {
        isErr: false,
        msg: '',
      },
      list: {},
      form: {
        ownersClubId: '',
        password: '',
        passwordConfirm: '',
        sei: '',
        mei: '',
        seikana: '',
        meikana: '',
        zip: '',
        address: '',
        addressLocal: '',
        email: '',
        tel: '',
        home_tel: '',
        income: '',
        budget: '',
        desiredRent: '',
        prefecture: '',
        area: '',
        desiredMonthlyPrice: '',
        motiveForSearch: [],
        motiveForSearchAdditional: '',
        desiredPriceRangeUpper: '9999999999999',
        desiredPriceRangeLower: '0',
        desiredKind: [],
        desiredAreaPref: '',
        desiredAreaCity: '',
      },
      citiesLoading: false,
      cityList: null,
      submitting: false,
      agreement: false,
      priceRangesLower,
      priceRangesUpper,
    };
  },
  mounted() {
    const querystring = window.location.search;
    this.query = [...new URLSearchParams(querystring).entries()]
      .reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});
    this.form.email = this.query.email;
    // this.preFill();
  },
  methods: {
    async getCities(pref_name) {
      try {
        this.form.address = "";
        this.citiesLoading = true;
        this.cityList = null;
        const prefecture = this.preflist.find(item => item.pref_name === pref_name);
        this.cityList = await this.getCityList(prefecture.pref_code)
      } catch(e) {
        console.log(e);
      } finally {
        this.citiesLoading = false;
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();

      if(!isValid) {
        const errorRef = Object.values(this.$refs.observer.refs).filter((ref) => ref.errors.length > 0)[0].$el
        window.scrollTo({top: errorRef.offsetTop});
      } else {
        this.err.isErr = false;
        this.err.msg = '';
        this.$emit('updated', 'confirm', this.form);
      }
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    yubinBango() {
      let _this = this
      new YubinBangoCore(this.form.zip, function(addr) {
        _this.form.prefecture  = addr.region
        _this.$refs.prefCitySelect.getCities(addr.region)
        _this.form.address = addr.locality
        if(_this.form.addressLocal.indexOf(addr.street)) {
          _this.form.addressLocal = addr.street
        }
      })
    }
  //   preFill() {
  //     this.form = {
  //       email: 'test@test.com',
  //       password: 'sometimeago123',
  //       passwordConfirm: 'sometimeago123',
  //       sei: 'test',
  //       mei: 'test',
  //       seikana: 'test',
  //       meikana: 'test',
  //       zip: '141-0041',
  //       address: '久慈市',
  //       addressLocal: 'test address',
  //       tel: '032-1233-3123',
  //       prefecture: '岩手県',
  //       // dateOfBirth: '',
  //       // familySize: '',
  //       // currentPropertyStatus: '賃貸',
  //       desiredMonthlyPrice: '10',
  //       // daysOff: [],
  //       // income: '',
  //       // budget: '',
  //       // replacement: true,
  //       motiveForSearch: ['ご出産'],
  //       motiveForSearchAdditional: '',
  //       desiredPriceRangeUpper: '9999999999999',
  //       desiredPriceRangeLower: '0',
  //       desiredKind: ['中古マンション'],
  //       // desiredFloorPlan: [],
  //       desiredAreaPref: '',
  //       desiredAreaCity: '',
  //       // desiredLine: '',
  //       // desiredStation: '',
  //       // desiredTimeToStation: '',
  //       // otherRequirements: [],
  //     }
  //   }
  },
  watch: {
  // eslint-disable-next-line func-names
    'form.motiveForSearch': function (newValue) {
      if (!newValue.includes('その他')) {
        this.form.motiveForSearchAdditional = '';
      }
    },
    'form.zip': function() {
      const zipRegexp = /^\d{3}-?\d{4}$/
      if(!this.form.zip.match(zipRegexp)) {
        return;
      }
      this.yubinBango()
    },
  },
  computed: {
    verifiedCity() {
      if (!this.cityList) {
        return "";
      }
      const possibleValues = this.cityList.map(c => c.city_name)
      if (!possibleValues.includes(this.form.address)) {
        return "";
      }
      return this.form.address;
    },
  }
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@charset "UTF-8";
@import "../../../assets/style/parts_responsive.scss";

.p-form {
  input {
    height: 32px;
  }
}

#user-fixed-email {
  font-size: 16px;
  margin-top: 7px;
  margin-left: 6px;
}

.p-standard-h1 {
  text-align: center;
}

#profile-change-form {
  margin: 20px 14px;


  .profile-change-field {
    padding: 12px 0;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    min-height:44px;

    ::v-deep input,
    ::v-deep select {
      width: 100%;
      margin-top: 0px;
      height: 34px;
    }

    .c-check-wrapper {
      position: relative;
      margin-top: 9px;
      margin-right: 22px;
      margin-bottom: 10px;
    }

    input[type=checkbox] {
      display: none;
    }

    input[type=checkbox]:checked + label > .check-icon {
      background-image: url(../../../assets/checkbox-rounded-checked@2x.png);
      background-repeat: no-repeat;
      background-size: 100%;
      height: 24px;
      width: 24px;
      position: absolute;
      bottom: 2px;
      left: 0;
      cursor: pointer;
    }

    input[type=checkbox] + label > .check-icon {
      background-image: url(../../../assets/checkbox-rounded-unchecked@2x.png);
      background-size: 86%;
      background-repeat: no-repeat;
      height: 24px;
      width: 24px;
      position: absolute;
      bottom: 0px;
      bottom: 1px;
      left: 0;
      cursor: pointer;
    }

    .c-checkbox-label {
      font-weight: 400;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 100%;
      margin-left: 32px;
      padding-bottom: 6px;
      font-size: 14px;
      cursor: pointer;
    }

    .input-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;


      .address-select-wrapper {
        display: flex;
        margin-top: 10px;
        width: 100%;

        ::v-deep .select-wrapper{
          flex: 1
        }
        ::v-deep .select-wrapper:first-child {
          margin-right: 8px;
        }
        ::v-deep select {
          width: 100%;
        }

      }
    }
    #desired-rent-input-wrapper {
      width: 54%;
      .inner-input-wrapper {
        position: relative;
      }
    }
    .c-price-select-inner-input-wrapper {
      width: 158px;
    }
    .c-input-finance-field-width {
      position: relative;
      width: 94px;
    }
    label {
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      height: 24px;
      span {
        bottom: 4px;
        &.required {
          margin-left: 1em;
          padding: 6px;
          font-size: 12px;
          color: white;
          background-color: red;
          position: relative;
          bottom: 2px;
        }
        &.optional {
          margin-left: 1em;
          padding: 6px;
          font-size: 12px;
          color: white;
          background-color: #707070;
          position: relative;
          bottom: 2px;
        }
      }
    }

    &.desired-rent-field {
      input {
        width: 60%;
      }
    }
  }

  .double-input {
    input {
      flex: 1;
      margin-right: 8px;
    }

  }
}

.c-agree-check-wrapper {
  margin: 28px 0 -10px;
  padding: 0 6px;
}

.c-policy-wrapper {
  margin-top: 18px;
}

.c-contract,
.c-privacy-policy {
  font-size: 14px;
  padding-left: 4px;
  margin-top: 8px;
  display: flex;
  align-items: center;

  p {
    margin-left: 4px;
  }
}

.c-goto-top-button-wrapper {
  width: 222px;
  margin: 30px auto;

  input {
    height: 41px;
    font-size: 14px;
  }
}

.c-price-separator {
  font-size: 20px;
  color: #555555;
  display: block;
  padding: 8px;
  display: flex;
  margin: auto 0;
}

#desired-area-field {
  ::v-deep .address-select-wrapper {
    margin-top: 0px !important;
  }
}

@media (min-width: 640px) {

  #profile-change-form {
    width: 660px;
    margin: auto;
    .profile-change-field {
      align-items: center;

      &.align-start {
        align-items: start;
      }
      padding: 6px 0;

      input,
      select {
        width: 100%;
      }
      .input-wrapper {
        width: 54%;
        input {
          width: 100%;
        }
        #address-select-wrapper {
          width: 75%;
        }
      }
      #desired-rent-input-wrapper {
        width: 54%;
      }
      label {
        align-self: start;
        display: flex;
        justify-content: space-between;
        padding-right: 24px;
        height: 100%;
        width: 46%;
        text-align: left;
        margin-top: 6px;
        align-items: center;
      }
    }

    .name-field {
      margin-top: 26px;
    }

    .inner-input-wrapper {
      width: 75%;
    }
    .standard-field-input-wrapper {
      width: 54%;
      .inner-input-wrapper {
        position: relative;
      }
    }

    .login-field {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      label {
        width: 30%;
      }
      .input-wrapper {
        margin-top: 0px;
        width: 64%;
      }
    }
  }

  .c-agree-check-wrapper {
    padding: 0;
  }


  .c-policy-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

</style>
