import Meta from '@/assets/json/meta.json';

// 必ずmountedで呼び出すこと（各NODEにアクセスするため）

export default {
  methods: {
    metaSetForDefault() {
      document.title = Meta.index.title;
      // no meta for now
      const meta = document.querySelector('meta[name="description"]');
      meta.parentNode. removeChild(meta);
    },
    metaSetForDetail(pref, city) {
      let title = Meta.detail.title;
      // let description = Meta.detail.description;

      title = title.replace('{name}', this.prop.name);
      title = title.replace('{prefecture}', pref);
      title = title.replace('{city}', city);
      title = title.replace('{access}', this.prop.access_name_1);
      title = title.replace('{kind}', this.prop.kind);

      // description = description.replace('{name}', this.prop.name);
      // description = description.replace('{contactname}', this.prop.contact_name);
      // description = description.replace('{prefecture}', pref);
      // description = description.replace('{city}', city);
      // description = description.replace('{access}', this.prop.access_name_1);

      document.title = title;
      // document.querySelector('meta[name="description"]').setAttribute('content', description);
      // no meta for now
      const meta = document.querySelector('meta[name="description"]');
      meta.parentNode. removeChild(meta);
    },
    metaSetForList(pref, city = null) {
      let title;
      // let description;
      if (city !== null) {
        title = Meta.searchList.title.replace('{city}', city);
        // description = Meta.searchList.description.replace('{city}', city);
      } else {
        title = Meta.searchList.title.replace('{city}', pref);
        // description = Meta.searchList.description.replace('{city}', '');
      }
      // description = description.replace('{prefecture}', pref);
      document.title = title;
      // document.querySelector('meta[name="description"]').setAttribute('content', description);
      // no meta for now
      const meta = document.querySelector('meta[name="description"]');
      meta.parentNode. removeChild(meta);
    },

  },
};
