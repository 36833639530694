<template>
  <transition name="fade">
    <div v-if="show" class="tooltip-position-wrapper">
      <div :class="expanded ? 'tooltip-root tall' : 'tooltip-root'" v-if="text" :style="computedRootStyle">
        <div class="tooltip-wrapper">
          <div class="bubble-wrapper" @click="handleClick">
            <div class="floating-tooltip">
              <div class="tooltip-text-wrapper">
                <span>{{ text }}</span>
              </div>
            </div>
            <div :class="arrowClass">
              <div class="inner-arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FloatingTooltip',
  props: {
    text: String,
    show: Boolean,
    expanded: {
      type: Boolean,
      default: false,
    },
    arrowPosition: {
      type: String,
      default: 'center',
    },
    positionBottom: {
      type: Number,
      default: 2,
    },
  },
  methods: {
    handleClick() {
      this.$emit('clicked');
    },
  },
  computed: {
    arrowClass() {
      return `arrow arrow-${this.arrowPosition}`;
    },
    computedRootStyle() {
      return `bottom: ${this.positionBottom}px`;
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip-position-wrapper {
  position: relative;
}

.tooltip-root {
  position: absolute;
}

.tooltip-wrapper {
  position: relative;
  bottom: -14px;
  left: 0px;
  height: 60px;
  display: flex;
  z-index: 10;
}
.bubble-wrapper {
  margin-top: auto;
}
.floating-tooltip {
  background-color: white;
  min-width: 210px;
  max-width: 300px;
  padding: 12px 20px;
  border: 1px solid red;
  border-radius: 22px;
  box-shadow: 0px 5px 10px -7px;
  font-size: 14px;
}

span {
  color: #666666;
  overflow-wrap: anywhere;
}

.arrow {
  z-index: 10;
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 8px 0 8px;
  border-color: red transparent transparent transparent;
}

.inner-arrow {
  left: -8px;
  top: -14px;
  z-index: 10;
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 8px 0 8px;
  border-color: white transparent transparent transparent;
}

.arrow-left {
  left: 19%;
}

.arrow-center {
  left: 47%;
}

.tall {
  .tooltip-wrapper {
    bottom: -14px;
    height: 100px;
  }
}

.fade-enter-active, .fade-leave-active {
  z-index: 10;
  transition: opacity .5s;
  pointer-events: none;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  pointer-events: none;
  z-index: 10;
  opacity: 0;
}

</style>
