<template>
  <div id="profile-change-wrapper">
    <h2 class="my-page-header">ご登録内容の変更</h2>
    <p id="profile-complete-message">お客様情報の変更が完了しました</p>
    <div class="button-wrapper">
      <button @click="navigateToTop" class="submit-button top-button round-button">
        マイページTOPへ
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyPageModifyProfileComplete',
  methods: {
    navigateToTop() {
      this.$emit('updated', 'top');
    }
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/style/parts_responsive.scss";

#profile-complete-message {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  padding: 12px 0;
}

.button-wrapper {
  display: flex;
  height: 200px;
}

@media (min-width: 640px) {
  #profile-change-wrapper {
    margin: auto;
    height: 385px;
  }
}
</style>
