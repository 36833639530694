import CommonAgreeCheck from "@/components/parts/CommonAgreeCheck";

export default {
	components: { CommonAgreeCheck },
  data() {
    return {
      agreeChecked: false,
      agreeTooltip: false,
    };
  },
  methods: {
    hideAgreeTooltip() {
      this.agreeTooltip = false;
    },
    showAgreeTooltip() {
      this.agreeTooltip = true;
    },
  },
};
