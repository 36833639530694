<template>
  <validation-wrapper :name="name" ref="prov" :vid="vid" class="validation-wrapper" :rules="rules">
    <template v-slot:default="slotProps">
      <TelephoneFieldInput v-model="tel" v-bind="$attrs" @focus="slotProps.clear" @blur="$emit('blur')"/>
    </template>
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/components/common/validation/ValidationWrapper';
import TelephoneFieldInput from '@/components/common/validation/telephone/TelephoneFieldInput';

export default {
  name: 'ValidatedTelephoneField',
  props: ['value', 'rules', 'vid', 'expandedTooltip', 'name'],
  components: { TelephoneFieldInput, ValidationWrapper },
  data() {
    return {
      tel: '',
    };
  },
  watch: {
    tel(value) {
      this.$emit('input', value);
    },
    value: {
      immediate: true,
      handler(newValue) {
        this.tel = newValue;
      },
    },
  },
};
</script>

