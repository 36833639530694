import {
  registerUser,
  completeRegistration,
  initiateForgotPassword,
  completeNewPassword,
  updateAttributes
} from "@/api/registration";
import { Auth } from "aws-amplify";

const parseErrorCode = e => {
  return e.code;
};

const signUp = async (
  email,
  enabledService,
  ownersStatus,
  kindOfMemberShopId
) => {
  const body = {
    email,
    enabledService,
    ownersStatus,
    kindOfMemberShopId
  };
  return registerUser(body);
};

const completeSignUpFlow = async (username, code, password, attributes) => {
  const body = {
    username,
    code,
    password,
    user_attributes: attributes
  };
  return completeRegistration(body);
};

const forgotPassword = async (email, location) => {
  const body = {
    email,
    redirectUrl: location
  };
  return initiateForgotPassword(body);
};

const completeForgotPassword = async (username, password, code) => {
  const body = {
    username,
    password,
    code
  };
  return completeNewPassword(body);
};

const updatePassword = async (password, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();
  const result = await Auth.changePassword(user, password, newPassword);
  return result;
};

const updateUserAttributes = async (username, attributes) => {
  const result = await updateAttributes(username, attributes);
  return result;
};

export {
  parseErrorCode,
  forgotPassword,
  completeForgotPassword,
  signUp,
  completeSignUpFlow,
  updatePassword,
  updateUserAttributes
};
