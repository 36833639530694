<template>
  <div class='c-mypagetop'>
    <!-- {{$route.params.userid}} -->
    <div class="c-owner-section">
      <img src="@/assets/keiai-plus-logo.png" class="c-ki-plus-logo"/>
      <div class="c-lead-wrapper">
        <div class="c-lead-text-block">
          <p class="c-lead-text"> 登録で<span class="c-lead-text--strong c-lead-text--lg">5,000</span><span class="c-lead-text--strong c-lead-text--md">ポイント</span>プレゼント！ </p>
        </div>
      </div>
      <h4 class="c-title">アプリの無料ダウンロードはこちら</h4>
      <div id="c-app-link-wrapper">
        <div class="c-app-link-block">
          <a href="https://apps.apple.com/jp/app/keiai%E3%83%97%E3%83%A9%E3%82%B9/id1615354717" class="c-app-link-badge">
            <img src="@/assets/resources/apple-store-badge.svg" alt="">
          </a>
          <div class="c-qr-code-wrapper">
            <img class="c-qr-code" src="@/assets/resources/apple-store-qr.png" alt="">
          </div>
        </div>
        <div class="c-app-link-block">
          <a href="https://play.google.com/store/apps/details?id=com.keiaistar.keiaiapp" class="c-app-link-badge">
            <img src="@/assets/resources/google-play-badge.svg" alt="">
          </a>
          <div class="c-qr-code-wrapper">
            <img class="c-qr-code" src="@/assets/resources/google-play-qr.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- <loading v&#45;if="!accessKey"/> -->
    <!-- <div v&#45;else class="c&#45;mypagetop&#45;&#45;access&#45;key"> -->
    <!--   <h3>KEIAI アプリアクセスキー</h3> -->
    <!--   <p>my KEIAIをご利用のお客様は、アプリアクセスキーをコピー＆ペーストすることで、KEIAIのアプリに簡単にログインすることができます。</p> -->
    <!--   <div class="c&#45;bubble&#45;section"> -->
    <!--     <div> -->
    <!--       <floating&#45;tooltip class="copy&#45;tooltip" text="コピーしました" :show="showTooltip"/> -->
    <!--     </div> -->
    <!--     <span v&#45;if="showAccessCode" id="c&#45;access&#45;key&#45;text">{{ accessKey }}</span> -->
    <!--     <span v&#45;else id="c&#45;access&#45;key&#45;hidden">●●●●●●●●</span> -->
    <!--   </div> -->
    <!--   <div class="c&#45;button&#45;wrapper"> -->
    <!--     <button @click="copyToClipboard">コピー</button> -->
    <!--     <button @click="showAccessCode = !showAccessCode">{{ showAccessCode ? "非表示" : "表示"}}</button> -->
    <!--   </div> -->
    <!-- </div> -->

  </div>
</template>

<script>
import moment from 'moment';
import MixinAPI from '@/plugins/mixin-api';
// import { getAccessKey } from '@/api/mypage';

moment.locale('ja');

export default {
  name: 'MyPageTop',
  mixins: [MixinAPI],
  props: ['isOwner'],
  data() {
    return {
      infoList: null,
      showAccessCode: false,
      accessKey: "",
      showTooltip: false
    };
  },
  async created() {
    // this.accessKey = await getAccessKey();
    const r = await this.getMypageInfoList();
    this.infoList = r.data.contents;
  },
  methods: {
    isDateFormated(val) {
      return moment(val).format('YYYY/MM/DD');
    },
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.accessKey;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.showTooltip = true;
      setTimeout(() => {this.showTooltip = false}, 3000)
    }
  },

};
</script>

<style scoped lang='scss'>
.c-mypagetop {
  .c-owner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    .c-ki-plus-logo {
      width: 200px;
    }
    .c-ki-subheader-wrapper {
      margin-top: 30px;
      position: relative;
      width: 500px;
      height: 116px;
      background: transparent;
      top: -120px;
      margin-bottom: -80px;
      .c-ki-subheader-img {
        clip: rect(110px, 2000px, 160px, 0);
        position: absolute;
      }
    }
    .c-lead-wrapper {
      margin-top: 30px;
      position: relative;
      max-width: 600px;
      display: flex;
      justify-content: center;
    }
    .c-lead-text-block {
      position: relative;
      &::before,
      &::after {
        content: '';
        width: 30px;
        height: 2px;
        background-color: #222;
        display: block;
        position: absolute;
        top: 50%;
      }
      &::before {
        transform: translateY(-50%) rotate(60deg);
        left: -40px;
      }
      &::after {
        transform: translateY(-50%) rotate(-60deg);
        right: -40px;
      }
    }
    .c-lead-text {
      font-size: 20px;
      font-weight: bold;
      color: #666666;
      &--strong {
        font-weight: bold;
        position: relative;
        color: red;
        display: inline-block;
      }
      &--lg {
        font-size: 30px;
        margin-left: 5px;
      }
      &--md {
        font-size: 26px;
        margin-right: 5px;
      }
    }
    // .c-header-icon {
    //   margin-top: 40px;
    //   height: 60px;
    //   width: 300px;
    // }
    .c-title {
      margin-top: 120px;
      font-size: 24px;
      font-weight: bold;
    }
    #c-app-link-wrapper {
      margin-top: 35px;
      margin-bottom: 55px;
      display: flex;
      align-items: center;
      .c-app-link-block {
        display: flex;
        align-items: center;
        &:first-child {
          margin-right: 65px;
        }
      }
      .c-app-link-badge {
        margin-right: 15px;
      }



      .c-qr-code-wrapper {
        border: 1px solid #222222;
      }
      .c-qr-code {
        height: 120px;
        width: 120px;
        margin: 10px;
      }
    }
  }

  .c-bubble-section {
    border-radius: 14px;
    padding: 24px;
    background-color: #FAFAFA;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
  }

  &--info{
    height: 320px;
    overflow: auto;

    ul {
      li {
        line-height: 1.4em;;
        font-size: 14px;
        margin-bottom: 8px;
        display: flex;

        span {
          word-break: break-all;
        }

        .c-date-span {
          width: 96px;
        }

        .c-message-span {
          flex: 1;
        }

      }
    }

  }

  &--access-key{
    margin: 40px auto;
    max-width: 480px;
    text-align: center;

    p {
      margin-top: 22px;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
    }

    #c-access-key-text {
      font-weight: 700;
      font-size: 16px;
    }

    #c-access-key-hidden {
      letter-spacing: 4px;
      font-size: 9px;
    }

    .c-bubble-section {
      margin-top: 20px;
      height: 62px;

      .copy-tooltip {
        bottom: 32px;
        left: 106px;
      }
    }

    .c-button-wrapper {
      margin-top: 16px;
      button {
        font-size: 16px;
        padding: 20px;
        width: 162px;
        border-radius: 30px;
        border: 1px solid #CCCCCC;
      }

      button:last-of-type {
        margin-left: 12px;
      }
    }
  }
  h2 {
    text-align: left;
    line-height:1em;
    margin:0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
  }

}

.left-margin {
  margin-left: 10px;
}

@media (max-width: 639px) {
  .c-mypagetop {
    padding: 0px;
    .c-owner-section {
      margin-top: 50px;
      .c-ki-plus-logo {
        width: 180px;
      }
      .c-lead-text-block {
        &::before,
        &::after {
          width: 26px;
        }
        &::before {
          left: -25px;
        }
        &::after {
          right: -25px;
        }
      }
      .c-lead-text {
        font-size: 16px;
        &--lg {
          font-size: 24px;
          margin-left: 2px;
        }
        &--md {
          font-size: 20px;
          margin-right: 2px;
        }
      }
      .c-title {
        margin-top: 75px;
        font-size: 20px;
      }
      #c-app-link-wrapper {
        margin-top: 20px;
        .c-app-link-block {
          &:first-child {
            margin-right: 10px;
          }
        }
        .c-app-link-badge {
          margin-right: 0;
        }
        .c-qr-code-wrapper {
          display: none;
        }
      }
    }
  }

  // .c-owner-section {
  //   #c-app-link-wrapper {
      // .c-qr-code {
      //   display: none;
      // }
      // .c-app-store-link {
      // }
    // }
  // }
}
</style>
