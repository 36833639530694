<template>
  <ValidationWrapper :rules="rules" :vid="vid" :expandedTooltip="expandedTooltip" :tooltipArrowPosition="tooltipArrowPosition" :mode="mode">
    <template v-slot:default="slotProps">
      <input v-bind="$attrs" @focus="slotProps.clear" :value="value" @input="innerValue = $event.target.value"/>
    </template>
  </ValidationWrapper>
</template>

<script>
import ValidationWrapper from '@/components/common/validation/ValidationWrapper';

export default {
  name: 'ValidatedInput',
  components: { ValidationWrapper },
  props: {
    value: [String, Number],
    rules: String,
    vid: String,
    expandedTooltip: Boolean,
    tooltipArrowPosition: String,
    mode: String,
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
  margin-top: 0px;
  height: 34px;
}
</style>
