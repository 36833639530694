<template>
  <div>
    <div id="regist-wrapper">
      <member-regist
        v-if="pages === 'regist'"
        @updated="slideView"
        />
      <member-sent v-if="pages === 'sent'" @updated="slideView" />
    </div>
  </div>
</template>

<script>
import IsMobileMixin from '@/plugins/isMobile';

import Meta from '@/assets/json/meta.json';
import MixinAPI from '@/plugins/mixin-api';


import MemberRegist from '@/components/member/regist/MemberRegist';
import MemberSent from '@/components/member/regist/MemberSent';

export default {
  name: 'Signup',
  components: {
    MemberRegist,
    MemberSent,
  },
  data() {
    return {
      pages: 'regist',
    };
  },
  async mounted() {
    document.title = Meta.default.title;
  },
  mixins: [IsMobileMixin, MixinAPI],
  methods: {
    slideView(nextView, args = []) {
      this.pages = nextView;
      this.args = args;
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@charset "UTF-8";
@media screen and (max-width: 1199px) {
  @import "../../assets/style/glocal_scss_sp.scss";
  @import "../../assets/style/parts_scss_sp.scss";
}
@media screen and (min-width: 1200px) {
  @import "../../assets/style/glocal_scss_pc.scss";
  @import "../../assets/style/parts_scss_pc.scss";
  body {
    margin: 0;
  }
}
@import "../../assets/style/parts_responsive.scss";

@media screen and (min-width: 640px) {
  #regist-wrapper {
    padding: 30px 15px;
    margin: auto;
  }
}

@media (min-width: 1000px) {
  #regist-wrapper {
    width: 100%;
  }

  #profile-change-wrapper {
    padding: 20px;

    #profile-change-inner-wrapper {
      width: 800px;
    }
  }

  #profile-change-form {
    .profile-change-field {

      label {
        width: 40%;
      }
      .input-wrapper {
        width: 60%;
        #address-select-wrapper {
          width: 100%;
        }

        &.name-input-wrapper {
          .inner-input-wrapper {
            flex: 1;
          }
          .top-margin {
            margin-top: 0;
            margin-left: 8px;
          }
        }
      }
      #desired-rent-input-wrapper {
        width: 60%;
      }
    }
  }

  .inner-input-wrapper {
    width: 100%;
    flex: 1;
  }

  .inner-input-wrapper-fullscreen-short {
    width: 48%;
  }
}

</style>
