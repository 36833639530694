<template>
  <div class="outer-wrapper">
    <div v-if="error" class="inner-wrapper">
      <h3>
        {{ error }}
      </h3>
    </div>
  </div>
</template>

<script>
import { parseUserLogin } from '@/utils/auth';

export default {
  name: 'ReceiveLogin',
  components: {},
  data() {
    return {
      error: null,
    };
  },
  async created() {
    if (this.$route.query.error) {
      this.error = this.$route.query.error_description;
    } else {
      const redirect = await parseUserLogin();
      this.$router.push(redirect || '/');
    }
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
.inner-wrapper {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: red;
    text-align: center;
  }
}
</style>
